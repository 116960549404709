import React, { FC } from 'react';
import * as yup from 'yup';
import { Button, Link, Stack, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { recoverPassword } from '../../shared/api/auth/auth';
import useAlert from '../../shared/hooks/useAlert';

interface RecoverPasswordForm {
  email: string;
}

const recoverSchema = yup.object().shape({
  email: yup.string().required('Email is required'),
});

export const RecoverPassword: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RecoverPasswordForm>({
    defaultValues: { email: '' },
    resolver: yupResolver(recoverSchema),
  });

  const navigate = useNavigate();
  const { addAlert } = useAlert();

  const sendMutation = useMutation(
    (f: RecoverPasswordForm) => recoverPassword(f.email),
    {
      onSuccess: () => {
        navigate('../recovery_sent');
      },
      onError: () => {
        addAlert('An error has occurred', 'error');
      },
    }
  );

  const onFormSubmit = handleSubmit((data: RecoverPasswordForm) => {
    sendMutation.mutate(data);
  });

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={onFormSubmit}
    >
      <Stack spacing={2}>
        <Typography variant="h6">Recover Password</Typography>
        <Typography variant="subtitle1">
          Enter your email to recover your password.
        </Typography>
        <TextField
          {...register('email')}
          id="email"
          label="Email"
          type="text"
          fullWidth
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <Button type="submit" variant="contained">
          Send
        </Button>
        <Link component={RouterLink} underline="none" to="../signin">
          Back to login
        </Link>
      </Stack>
    </form>
  );
};
