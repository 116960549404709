import { Divider, Tab, Tabs } from '@mui/material';
import React, { FC } from 'react';
import { TabPanel } from '../../components/TabUtils/TabUtils';
import { Link, Outlet, useLocation } from 'react-router-dom';
import DetailLayout from '../../layouts/DetailLayout/DetailLayout';

export const Reports: FC = () => {
  const currentPath = useLocation().pathname.split('/');
  const currentPage = currentPath[currentPath.length - 1];

  // Return an Outlet, so we can redirect to the default route without rendering the tabs
  if (currentPage === 'reports') return <Outlet />;

  return (
    <DetailLayout
      title="Reports"
      subtitle="Consult and download reports"
      hasBackButton={false}
    >
      <Tabs value={currentPage}>
        <Tab
          label="Assignments"
          value="assignments"
          component={Link}
          to="assignments"
        />
      </Tabs>
      <TabPanel>
        {' '}
        <Divider />
        <Outlet />
      </TabPanel>
    </DetailLayout>
  );
};
