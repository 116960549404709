import React, { FC, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Administrator } from '../../shared/types';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ModalParams } from '../../shared/hooks/useModal';
import { yupResolver } from '@hookform/resolvers/yup';
import useAlert from '../../shared/hooks/useAlert';
import { useMutation, useQueryClient } from 'react-query';
import { ROLES } from '../../shared/constants';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { useNavigate } from 'react-router-dom';
import {
  addAdministrator,
  deleteAdministrator,
  editAdministrator,
} from '../../shared/api/administrators/administrators';
import { AxiosError } from 'axios';

type AdministratorModalProps = ModalParams & {
  adminData?: Administrator;
};

const administratorSchema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  email: yup
    .string()
    .required('Email is required')
    .email('Email must be valid'),
  role: yup.string().required('Role is required'),
});

export const AdministratorModal: FC<AdministratorModalProps> = ({
  isOpen,
  close,
  adminData,
}) => {
  const {
    setError,
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<Administrator>({
    defaultValues: {
      first_name: '' ?? adminData?.first_name,
      last_name: '' ?? adminData?.last_name,
      email: '' ?? adminData?.email,
      is_active: true,
      role: '' ?? adminData?.role,
    },
    resolver: yupResolver(administratorSchema),
  });

  const [apiError, setApiError] = useState<string>();
  const [modalState, setModalState] = useState<'edit' | 'confirmation'>('edit');
  const isEditing = !!adminData;

  const closeCleanup = (): void => {
    reset();
    setApiError('');
    close();
  };

  useEffect(() => {
    reset(adminData);
  }, [reset, adminData]);

  const { addAlert } = useAlert();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const addMutation = useMutation(
    (admin: Administrator) => addAdministrator(admin),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('administrators');
        addAlert('Administrator created successfully', 'success');
        closeCleanup();
      },
      onError: (err: AxiosError<Administrator>) => {
        if (err?.response?.data?.email) {
          setError('email', {
            type: 'server',
            message: err.response.data.email,
          });
        }
        setApiError('An error has occurred');
      },
    }
  );
  const editMutation = useMutation(
    (admin: Administrator) => editAdministrator(admin),
    {
      onSuccess: async () => {
        // TODO: Possibly id is an integer which would not match Admin Detail
        const queryKey = ['administrators', adminData?.id.toString()];
        await queryClient.invalidateQueries(queryKey);
        addAlert('Administrator updated successfully', 'success');
        closeCleanup();
      },
      onError: (err: AxiosError<Administrator>) => {
        if (err?.response?.data?.email) {
          setError('email', {
            type: 'server',
            message: err.response.data.email,
          });
        }
        setApiError('An error has occurred');
        setModalState('edit');
      },
    }
  );
  const deleteMutation = useMutation(() => deleteAdministrator(adminData?.id), {
    onSuccess: async () => {
      addAlert('Successfully deleted Administrator.', 'success', {
        dismissOnLocationChange: false,
        timeoutToDismiss: 5000,
      });
      closeCleanup();
      navigate('/administrators');
      await queryClient.invalidateQueries('administrators');
    },
    onError: () => {
      setApiError('An error has occurred');
      setModalState('edit');
    },
  });

  const submitHandler: SubmitHandler<Administrator> = (data): void => {
    if (data.id) {
      editMutation.mutate(data);
    } else {
      addMutation.mutate(data);
    }
  };

  return modalState === 'edit' ? (
    <Dialog open={isOpen} onClose={() => null} maxWidth="sm" fullWidth>
      <form
        noValidate
        autoComplete="off"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitHandler)}
      >
        <DialogTitle>
          {isEditing ? 'Edit ' : 'Add New '}
          Administrator
        </DialogTitle>
        <DialogContent>
          {apiError ? (
            <Box mb={3}>
              <Alert severity="error" variant="filled">
                {apiError}
              </Alert>
            </Box>
          ) : null}
          <Stack mt={2} spacing={4}>
            <TextField
              {...register('first_name')}
              id="first_name"
              label="First Name"
              type="text"
              autoComplete="off"
              fullWidth
              error={!!errors.first_name}
              helperText={errors.first_name?.message}
            />
            <TextField
              {...register('last_name')}
              id="last_name"
              label="Last Name"
              type="text"
              autoComplete="off"
              fullWidth
              error={!!errors.last_name}
              helperText={errors.last_name?.message}
            />
            <TextField
              {...register('email')}
              id="email"
              label="Email"
              type="text"
              autoComplete="off"
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            <Controller
              name="role"
              control={control}
              render={({ field }): JSX.Element => (
                <TextField
                  {...field}
                  label="Role"
                  select
                  fullWidth
                  error={!!errors.role}
                  helperText={errors.role?.message}
                >
                  {ROLES.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ mx: 2, mb: 1 }}>
          {isEditing ? (
            <Button
              sx={{ ml: 0, mr: 'auto', color: 'error.main' }}
              onClick={(): void => setModalState('confirmation')}
            >
              Delete Administrator
            </Button>
          ) : null}
          <Button onClick={closeCleanup}>Cancel</Button>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  ) : (
    <ConfirmationDialog
      title="Delete Administrator?"
      description="Are you sure you want to delete this Administrator? All information will be lost."
      onConfirmation={(): void => deleteMutation.mutate()}
      confirmationLabel={<>Yes, Delete</>}
      onCancel={closeCleanup}
    />
  );
};
