import { Paginated, YardJockey } from '../../types';
import { apiClient } from '../http-common';

export const fetchPaginatedYardJockeys = (
  page = 1,
  search?: string
): Promise<Paginated<YardJockey>> => {
  return apiClient
    .get<Paginated<YardJockey>>('jockeys/', { params: { page, search } })
    .then((res) => {
      return res.data;
    });
};

export const addYardJockey = (jockey: YardJockey): Promise<YardJockey> => {
  return apiClient.post<YardJockey>('jockeys/', jockey).then((res) => {
    return res.data;
  });
};

export const editYardJockey = (jockey: YardJockey): Promise<YardJockey> => {
  return apiClient
    .put<YardJockey>(`jockeys/${jockey.user_id}/`, jockey)
    .then((res) => {
      return res.data;
    });
};

export const deleteYardJockey = (jockeyId?: string): Promise<void> => {
  if (!jockeyId) {
    return Promise.reject('A jockeyId is required');
  }

  return apiClient.delete(`jockeys/${jockeyId}/`);
};
