import React, { FC } from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export const RecoverPasswordEmailSent: FC = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="h6">Email Sent!</Typography>
      <Typography variant="subtitle1">
        Check your inbox for instructions to reset your password.
      </Typography>
      <Link component={RouterLink} underline="none" to="../signin">
        Back to login
      </Link>
    </Stack>
  );
};
