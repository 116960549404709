import { Assignment, DetailedAssignment, Paginated } from '../../types';
import { apiClient } from '../http-common';
import { parseISO } from 'date-fns';

export interface AssignmentResponse
  extends Omit<Assignment, 'start_time' | 'end_time'> {
  driver_first_name: string;
  driver_last_name: string;
  trailer_license_plate: string;
  trailer_license_state: string;
  start_time: string;
  end_time: string;
}

const assignmentResponseToDetailed = (
  response: AssignmentResponse
): DetailedAssignment => ({
  assignmentId: response.id,
  driverId: response.driver_id,
  trailerId: response.trailer_id,
  loadId: response.load_id,
  startTime: parseISO(response.start_time),
  endTime: parseISO(response.end_time),
  driverFirstName: response.driver_first_name,
  driverLastName: response.driver_last_name,
  trailerPlate: response.trailer_license_plate,
  trailerPlateState: response.trailer_license_state,
});

export const fetchPaginatedAssignments = (
  page = 1,
  params = {}
): Promise<Paginated<DetailedAssignment>> =>
  apiClient
    .get<Paginated<AssignmentResponse>>('assignments/', {
      params: { page, ...params },
    })
    .then((res) => {
      return {
        ...res.data,
        results: res.data.results.map(assignmentResponseToDetailed),
      };
    });

export const fetchActiveAssignmentForCurrentDriver = (): Promise<
  DetailedAssignment | undefined
> => {
  return apiClient
    .get<AssignmentResponse[]>('assignments/', {
      params: { active: '', all: '' },
    })
    .then((res) => {
      if (!res.data || res.data.length === 0) return undefined;
      return assignmentResponseToDetailed(res.data[0]);
    });
};

export const createNewAssignment = (assignment: Assignment): Promise<void> => {
  return apiClient.post('assignments/', {
    ...assignment,
    start_time: assignment.start_time.toISOString(),
    end_time: assignment.end_time.toISOString(),
  });
};

export const editAssignment = (assignment: Assignment): Promise<void> =>
  apiClient.put(`assignments/${assignment.id}/`, {
    ...assignment,
    start_time: assignment.start_time.toISOString(),
    end_time: assignment.end_time.toISOString(),
  });

export const deleteAssignment = (assignmentId?: string): Promise<void> => {
  if (!assignmentId) {
    return Promise.reject('assignmentId is required');
  }
  return apiClient.delete(`assignments/${assignmentId}/`);
};

export const downloadAssignmentsAsCsv = (params = {}): Promise<Blob> => {
  return apiClient
    .get<Blob>('assignments/', {
      params: { all: '', ...params },
      headers: { Accept: 'text/csv' },
      responseType: 'blob',
    })
    .then((res) => {
      return res.data;
    });
};
