import { useCallback, useMemo, useState } from 'react';
import {
  getValueFromLocalStorage,
  setValueOnLocalStorage,
} from '../utils/localStorage';

export function useLocalStorage<T = Record<string, unknown>>(
  key: string,
  defaultValue?: T
): [
  value: T | undefined,
  setValue: (newValue?: T) => void,
  resetValue: () => void
] {
  const [storedValue, setStoredValue] = useState(
    getValueFromLocalStorage<T>(key, defaultValue)
  );

  const setValue = useCallback(
    (newValue?: T) => {
      setValueOnLocalStorage<T>(key, newValue, setStoredValue);
    },
    [key]
  );

  const restoreValueFromLocalStorage = useCallback(() => {
    setStoredValue(getValueFromLocalStorage<T>(key));
  }, [setStoredValue, key]);

  return useMemo(
    () => [storedValue, setValue, restoreValueFromLocalStorage],
    [setValue, storedValue, restoreValueFromLocalStorage]
  );
}
