import React from 'react';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TableChartIcon from '@mui/icons-material/TableChart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { DrawerMenuItem } from '../types';

export const DRAWER_WIDTH = 325;

export const MAIN_MENU_ITEMS: DrawerMenuItem[] = [
  {
    title: 'My Assignments',
    icon: <LocalShippingIcon />,
    path: '/assignments',
    users: ['DRIVER', 'SUPERUSER'],
  },
  {
    title: 'Trailers',
    icon: <LocalShippingIcon />,
    path: '/trailers',
    users: ['DISPATCHER', 'MANAGER', 'OWNER', 'SUPERUSER'],
  },
  {
    title: 'Drivers',
    icon: <PeopleAltIcon />,
    path: '/drivers',
    users: ['DISPATCHER', 'MANAGER', 'OWNER', 'SUPERUSER'],
  },
  {
    title: 'Jockeys',
    icon: <PeopleAltIcon />,
    path: '/jockeys',
    users: ['DISPATCHER', 'MANAGER', 'OWNER', 'SUPERUSER'],
  },
  {
    title: 'Active and Upcoming Assignments',
    icon: <TableChartIcon />,
    path: '/company-assignments',
    users: ['DISPATCHER', 'MANAGER', 'OWNER', 'SUPERUSER'],
  },
  {
    title: 'Reports',
    icon: <SummarizeIcon />,
    path: '/reports',
    users: ['OWNER', 'SUPERUSER'],
  },
  {
    title: 'Admins',
    icon: <AccountCircleIcon />,
    path: '/administrators',
    users: ['OWNER', 'SUPERUSER'],
  },
];

export const STATES: string[] = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

export const ROLES = [
  {
    value: 'OWNER',
    label: 'Owner',
  },
  {
    value: 'MANAGER',
    label: 'Manager',
  },
  {
    value: 'DISPATCHER',
    label: 'Dispatcher',
  },
] as const;
