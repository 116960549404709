import React, { FC, Fragment, ReactNode, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Sidebar from '../../components/Sidebar/Sidebar';
import SidebarBox from '../../components/SidebarBox/SidebarBox';
import DetailLayout from '../../layouts/DetailLayout/DetailLayout';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { Driver } from '../../shared/types';
import { WithLoading } from '../../components/LoadingSpinner/LoadingSpinner';
import useAlert from '../../shared/hooks/useAlert';
import { fetchDriverById } from '../../shared/api/drivers/drivers';
import { DetailListCardView } from '../../components/DetailListCardView/DetailListCardView';
import useModal, { useModalReturn } from '../../shared/hooks/useModal';
import { TrailerDriverAssignmentDialog } from '../../components/TrailerDriverAsssignmentDialog/TrailerDriverAssignmentDialog';
import { DriverModal } from '../../components/DriverModal/DriverModal';
import { statusLabel } from '../../shared/utils/DetailPagesUtils';
import { ResetPasswordButton } from '../../components/ResetPasswordButton/ResetPasswordButton';
import useAuth from '../../shared/hooks/useAuth';
import { hasMatchingRole } from '../../shared/utils/roleUtils';

type DriverParams = {
  driverId: string;
};

function DriverDetailPage(): JSX.Element {
  const { activeCompany } = useAuth();
  const userRoles = activeCompany?.role;
  const { driverId } = useParams<DriverParams>();
  const assignmentModal = useModal();
  const driverModal = useModal();

  const queryClient = useQueryClient();
  const { data, isLoading, error } = useQuery(
    ['driver', driverId],
    () => fetchDriverById(driverId),
    {
      initialData: () => {
        const driversQueryData: Driver[] | undefined = queryClient.getQueryData(
          ['drivers']
        );
        return driversQueryData?.find((d) => d.driver_id === driverId);
      },
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(['drivers'])?.dataUpdatedAt,
    }
  );

  const { addAlert } = useAlert();
  useEffect(() => {
    const isFetchedDataEmpty = !isLoading && !data;

    if (error || isFetchedDataEmpty) {
      addAlert('Unable to fetch Driver detail', 'error');
    }
  }, [addAlert, error, data, isLoading]);

  return (
    <DetailLayout
      title="Driver Info"
      actionLabel={
        hasMatchingRole(['SUPERUSER', 'OWNER', 'MANAGER'], userRoles)
          ? 'Edit Information'
          : undefined
      }
      action={
        hasMatchingRole(['SUPERUSER', 'OWNER', 'MANAGER'], userRoles)
          ? driverModal.open
          : undefined
      }
    >
      <Box
        sx={{
          display: { md: 'flex', sm: 'block' },
          gap: 3,
        }}
      >
        <Sidebar>
          <NoTrailerAssignedCard modalManagement={assignmentModal} />
        </Sidebar>
        <Box
          sx={{
            flex: '3.25 1 auto',
          }}
        >
          <WithLoading isLoading={isLoading && !data}>
            <DriverDetailCard driver={data} />
            {assignmentModal.isOpen ? (
              <TrailerDriverAssignmentDialog
                isOpen={assignmentModal.isOpen}
                onClose={assignmentModal.close}
                driverId={data?.driver_id}
              />
            ) : null}
          </WithLoading>
        </Box>
      </Box>
      {driverModal.isOpen ? (
        <DriverModal
          isOpen={driverModal.isOpen}
          close={driverModal.close}
          driverData={data}
        />
      ) : null}
    </DetailLayout>
  );
}

interface NoTrailerAssignedCardProps {
  modalManagement: useModalReturn;
}

const NoTrailerAssignedCard: FC<NoTrailerAssignedCardProps> = ({
  modalManagement,
}) => {
  return (
    <SidebarBox>
      <LocalShippingIcon fontSize="large" sx={{ mb: 1.5 }} />
      <Button variant="outlined" fullWidth onClick={modalManagement.open}>
        Create Assignment
      </Button>
    </SidebarBox>
  );
};

const DriverDetailCard: FC<{ driver?: Driver }> = ({ driver }) => {
  if (!driver) return <Fragment />;
  const labelsToValues: [string, ReactNode][] = [
    ['Driver Name', `${driver.first_name} ${driver.last_name}`],
    ['Driver Email', driver.email],
    ['Driver Phone', driver.phone_number],
    ["Commercial Driver's License", driver.cdl_id],
    ['License State', driver.cdl_state],
    ['Status', statusLabel(driver.is_active)],
    [
      'Password',
      <ResetPasswordButton
        key={`password-reset-${driver.email}`}
        email={driver.email}
      />,
    ],
  ];

  return <DetailListCardView labelsToValues={labelsToValues} />;
};

export default DriverDetailPage;
