export function getValueFromLocalStorage<T = Record<string, unknown>>(
  key: string,
  defaultValue?: T
) {
  try {
    const value = window.localStorage.getItem(key);
    if (value) {
      return JSON.parse(value) as T;
    } else {
      window.localStorage.setItem(key, JSON.stringify(defaultValue));
      return defaultValue;
    }
  } catch (err) {
    return defaultValue;
  }
}

export function setValueOnLocalStorage<T = Record<string, unknown>>(
  key: string,
  newValue?: T,
  callback: (value?: T) => void = () => {}
) {
  try {
    window.localStorage.setItem(key, JSON.stringify(newValue));
    window.dispatchEvent(new Event('localstorage'));
    callback(newValue);
  } catch (err) {
    console.log(err);
  }
}

export function removeValueFromLocalStorage(key: string): void {
  window.localStorage.removeItem(key);
}
