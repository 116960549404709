import React, { FC, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { yupResolver } from '@hookform/resolvers/yup';
import { Assignment, Driver, Trailer } from '../../shared/types';
import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchTrailerById, searchTrailers } from '../../shared/api/trailers';
import { addHours, startOfHour } from 'date-fns';
import {
  fetchDriverById,
  searchDrivers,
} from '../../shared/api/drivers/drivers';
import {
  createNewAssignment,
  deleteAssignment,
  editAssignment,
} from '../../shared/api/assignments/assignments';
import useAlert from '../../shared/hooks/useAlert';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { AutocompleteSearchField } from '../AutocompleteSearchField/AutocompleteSearchField';

export interface TrailerDriverAssignmentDialogProps {
  isOpen?: boolean;
  onClose?: () => void;
  trailerId?: string;
  driverId?: string;
  assignmentData?: Assignment;
  queryInvalidationKey?: QueryKey;
}

const assignmentSchema = yup.object().shape({
  driver_id: yup.string().required('Driver is required'),
  trailer_id: yup.string().required('Trailer is required'),
  load_id: yup.string().required('Load ID is required'),
  start_time: yup.date().required('Start time is required'),
  end_time: yup
    .date()
    .required('End time is required')
    .min(yup.ref('start_time'), 'End time cannot be before Start Time'),
});

export const TrailerDriverAssignmentDialog: FC<
  TrailerDriverAssignmentDialogProps
> = ({
  isOpen,
  onClose,
  trailerId,
  driverId,
  assignmentData,
  queryInvalidationKey,
}) => {
  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
    setValue,
    register,
  } = useForm<Assignment>({
    defaultValues: {
      driver_id: assignmentData?.driver_id ?? driverId ?? '',
      trailer_id: assignmentData?.trailer_id ?? trailerId ?? '',
      load_id: assignmentData?.load_id ?? '',
      start_time:
        assignmentData?.start_time ?? startOfHour(addHours(new Date(), 1)),
      end_time:
        assignmentData?.end_time ?? startOfHour(addHours(new Date(), 4)),
    },
    resolver: yupResolver(assignmentSchema),
  });
  const [selectedTrailer, setSelectedTrailer] = useState<Trailer | null>(null);
  const [selectedDriver, setSelectedDriver] = useState<Driver | null>(null);
  const [formTrailerId, formDriverId] = useWatch({
    control,
    name: ['trailer_id', 'driver_id'],
  });

  const selectedStartTime = useWatch({ control, name: 'start_time' });

  const [apiError, setApiError] = useState('');
  const [modalState, setModalState] = useState<'edit' | 'confirmation'>('edit');
  const isEditing = !!assignmentData;

  useEffect(() => {
    if (assignmentData) {
      reset(assignmentData);
    }
  }, [assignmentData, reset]);

  const onCloseWrapper = (): void => {
    reset();
    setApiError('');
    onClose && onClose();
  };

  useQuery(['trailer', formTrailerId], () => fetchTrailerById(formTrailerId), {
    enabled: !!formTrailerId && !selectedTrailer,
    onSuccess: (data) => setSelectedTrailer(data),
  });

  useQuery(['driver', formDriverId], () => fetchDriverById(formDriverId), {
    enabled: !!formDriverId && !selectedDriver,
    onSuccess: (data) => setSelectedDriver(data),
  });

  const { addAlert } = useAlert();

  const queryClient = useQueryClient();
  const newAssignmentMutation = useMutation(
    (newAssignment: Assignment) => createNewAssignment(newAssignment),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('assignments');
        addAlert('Assignment was successfully created', 'success');
        onCloseWrapper();
      },
      onError: () => setApiError('An error has occurred'),
    }
  );

  const editAssignmentMutation = useMutation(
    (assignment: Assignment) => editAssignment(assignment),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryInvalidationKey);
        addAlert('Assignment updated successfully', 'success');
        onCloseWrapper();
      },
      onError: () => setApiError('An error has occurred'),
    }
  );
  const deleteMutation = useMutation(
    () => deleteAssignment(assignmentData?.id),
    {
      onSuccess: async () => {
        addAlert('Successfully deleted Driver.', 'success');
        onCloseWrapper();
        await queryClient.invalidateQueries(queryInvalidationKey);
      },
      onError: () => {
        setApiError('An error has occurred');
        setModalState('edit');
      },
    }
  );

  const submit = (data: Assignment): void => {
    if (data.id) {
      editAssignmentMutation.mutate(data);
    } else {
      newAssignmentMutation.mutate(data);
    }
  };

  return modalState === 'edit' ? (
    <Dialog open={!!isOpen} onClose={onCloseWrapper} maxWidth="sm" fullWidth>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(submit)}>
        <DialogTitle>
          {isEditing
            ? 'Edit Driver Authorization'
            : 'Temporarily Authorize Driver'}
          <Typography variant="subtitle1" component="div">
            Enter the information to grant access to a driver
          </Typography>
        </DialogTitle>
        <DialogContent>
          {apiError ? (
            <Box mb={3}>
              <Alert severity="error" variant="filled">
                {apiError}
              </Alert>
            </Box>
          ) : null}
          <Stack
            sx={{ display: 'flex', flexDirection: 'column', gap: 4, mt: 2 }}
          >
            <AutocompleteSearchField
              getLabel={(t): string => t.license_plate}
              searchFn={searchTrailers}
              queryKey="trailers_search"
              onChange={(t): void => {
                setValue('trailer_id', t ? t.trailer_id : '');
                setSelectedTrailer(t);
              }}
              disabled={!!trailerId}
              value={selectedTrailer}
              error={!!errors.trailer_id}
              helperText={errors.trailer_id?.message}
              fieldLabel="Trailer"
              isOptionEqualToValue={(option, value): boolean =>
                option.trailer_id === value.trailer_id
              }
            />

            <AutocompleteSearchField
              getLabel={(d): string => `${d.first_name} ${d.last_name}`}
              searchFn={searchDrivers}
              queryKey={'drivers_search'}
              disabled={!!driverId}
              onChange={(d): void => {
                setValue('driver_id', d ? d.driver_id : '');
                setSelectedDriver(d);
              }}
              value={selectedDriver}
              error={!!errors.driver_id}
              helperText={errors.driver_id?.message}
              fieldLabel={'Driver'}
              isOptionEqualToValue={(option, value): boolean =>
                option.driver_id === value.driver_id
              }
            />

            <TextField
              {...register('load_id')}
              id="load_id"
              label="Load ID"
              type="text"
              autoComplete="off"
              fullWidth
              error={!!errors.load_id}
              helperText={errors.load_id?.message}
            />

            <Controller
              name="start_time"
              control={control}
              render={({ field }): JSX.Element => (
                <MobileDateTimePicker
                  label="Select Start Time"
                  disablePast
                  {...field}
                  renderInput={(params): JSX.Element => (
                    <TextField
                      {...params}
                      error={!!errors.start_time}
                      helperText={errors.start_time?.message}
                    />
                  )}
                />
              )}
            />
            <Controller
              name="end_time"
              control={control}
              render={({ field }): JSX.Element => (
                <MobileDateTimePicker
                  {...field}
                  label="Select End Time"
                  disablePast
                  minDateTime={selectedStartTime}
                  disabled={!selectedStartTime}
                  renderInput={(params): JSX.Element => (
                    <TextField
                      {...params}
                      error={!!errors.end_time}
                      helperText={errors.end_time?.message}
                    />
                  )}
                />
              )}
            />
            <Typography variant="subtitle1" sx={{ fontSize: '0.8rem' }}>
              All dates are displayed in the{' '}
              {Intl.DateTimeFormat().resolvedOptions().timeZone} timezone
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ mx: 2, mb: 2 }}>
          {isEditing ? (
            <Button
              sx={{ ml: 0, mr: 'auto', color: 'error.main' }}
              onClick={(): void => setModalState('confirmation')}
            >
              Delete Assignment
            </Button>
          ) : null}
          <Button onClick={onCloseWrapper}>Cancel</Button>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  ) : (
    <ConfirmationDialog
      title="Delete Assignment?"
      description={
        <>
          Are you sure you want to delete this Assignment? All information will
          be lost.
        </>
      }
      onConfirmation={(): void => deleteMutation.mutate()}
      confirmationLabel={<>Yes, Delete</>}
      onCancel={onCloseWrapper}
    />
  );
};
