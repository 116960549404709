import { styled, SxProps, Theme } from '@mui/material';
import React, { FC, Fragment } from 'react';
import CircleIcon from '@mui/icons-material/Circle';

const StatusIndicator = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  width: '100%',
}));

export interface TrailerStatusIndicatorProps {
  isTrailerStatusLoading?: boolean;
  isOnline?: boolean;
  sx?: SxProps<Theme>;
}

export const TrailerStatusIndicator: FC<TrailerStatusIndicatorProps> = ({
  isOnline,
  isTrailerStatusLoading,
  sx,
}) => {
  const getStatusLabel = (isOnline?: boolean): string => {
    switch (isOnline) {
      case undefined:
        return 'Unknown';
      case true:
        return 'Online';
      case false:
        return 'Offline';
    }
  };

  const statusColor = isOnline ? '#70C476' : '#8B92A5';

  return (
    <StatusIndicator sx={sx}>
      {isTrailerStatusLoading ? (
        'Querying trailer...'
      ) : (
        <Fragment>
          {getStatusLabel(isOnline)}
          <CircleIcon sx={{ color: statusColor, fontSize: 12 }} />
        </Fragment>
      )}
    </StatusIndicator>
  );
};
