import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import {
  getValueFromLocalStorage,
  removeValueFromLocalStorage,
  setValueOnLocalStorage,
} from '../utils/localStorage';
import { refreshAccessToken } from './users/refreshAccessToken';
import { APIError, CompanyRole, UserTokens } from '../types';

export type GenericErrorResponse = AxiosError<{ message: string[] }>;

export type APIFunction<
  RequestParams = Record<string, unknown>,
  ResponseTypeMapped = Record<string, unknown>
> = (requestParams: RequestParams) => Promise<ResponseTypeMapped>;

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const apiClient = axios.create({
  baseURL: BASE_URL,
});

apiClient.interceptors.request.use(
  (config) => {
    const user = getValueFromLocalStorage<UserTokens>('user');
    const company = getValueFromLocalStorage<CompanyRole>('activeCompany');
    if (user?.accessToken && company?.id) {
      config.headers = {
        ...(config.headers ?? {}),
        Authorization: `Bearer ${user.accessToken}`,
        [`TrailerPAS-Active-Company`]: company.id,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (res) => res,
  async (err: AxiosError<APIError>) => {
    const originalConfig = err.config as AxiosRequestConfig & {
      _retry?: boolean;
    };

    if (originalConfig.url !== '/auth/login' && err.response) {
      // Access Token was expired
      if (
        err.response.status === 403 &&
        err.response.data?.code === 'token_not_valid' &&
        !originalConfig._retry
      ) {
        try {
          originalConfig._retry = true;
          const user = getValueFromLocalStorage<UserTokens>('user');

          if (!user) {
            return Promise.reject(err);
          }

          const newTokens = await refreshAccessToken(user.refreshToken);
          setValueOnLocalStorage<UserTokens>('user', newTokens);

          return apiClient(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      } else if (
        err.response.data?.detail ===
        'You do not have permission to perform this action.'
      ) {
        return Promise.reject(err.response.data.detail);
      } else if (err?.response.status === 403 || err?.response.status === 401) {
        removeValueFromLocalStorage('user');

        // Note: this isn't a great long-term solution, but it is good enough now
        window.location.replace('/');
        return Promise.reject(err);
      }
    }

    return Promise.reject(err);
  }
);
