import React, { FC } from 'react';
import {
  Alert,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import useAlert from '../../shared/hooks/useAlert';
import { useMutation } from 'react-query';
import { ResetPasswordForm } from '../../shared/types';
import { resetPassword } from '../../shared/api/auth/auth';

type RecoverPasswordParams = {
  uid: string;
  token: string;
};

const resetPasswordSchema = yup.object().shape({
  new_password1: yup
    .string()
    .required('New password is required')
    .min(8, 'Must be at least 8 characters long')
    .test(
      'Does not have digits',
      () => 'Password must not be only digits',
      (v) => !!v && !/^\d+$/.test(v)
    ),
  new_password2: yup
    .string()
    .required('Password confirmation is required')
    .oneOf([yup.ref('new_password1'), null], 'Passwords must match'),
  uid: yup.string().required('UID is missing'),
  token: yup.string().required('Token is missing'),
});

export const ResetPassword: FC = () => {
  const { uid, token } = useParams<RecoverPasswordParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordForm>({
    defaultValues: {
      new_password1: '',
      new_password2: '',
      uid,
      token,
    },
    resolver: yupResolver(resetPasswordSchema),
  });

  const navigate = useNavigate();
  const { addAlert } = useAlert();

  const sendMutation = useMutation((r: ResetPasswordForm) => resetPassword(r), {
    onSuccess: () => {
      addAlert('Password successfully updated', 'success', {
        dismissOnLocationChange: false,
        timeoutToDismiss: 5000,
      });
      navigate('/auth/signin');
    },
    onError: () => {
      addAlert('An error has occurred', 'error');
    },
  });

  const onFormSubmit = handleSubmit((data: ResetPasswordForm) => {
    sendMutation.mutate(data);
  });

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={onFormSubmit}
    >
      <Stack spacing={2}>
        <Typography variant="h6">New password</Typography>
        <Typography variant="subtitle1">Set a new password.</Typography>
        {errors.uid || errors.token ? (
          <Box mb={3}>
            <Alert severity="error" variant="filled">
              {errors.uid?.message ?? errors.token?.message}
            </Alert>
          </Box>
        ) : null}
        <TextField
          {...register('new_password1')}
          id="password"
          label="Password"
          type="password"
          fullWidth
          error={!!errors.new_password1}
          helperText={errors.new_password1?.message}
        />
        <TextField
          {...register('new_password2')}
          id="password_confirmation"
          label="Password Confirmation"
          type="password"
          fullWidth
          error={!!errors.new_password2}
          helperText={errors.new_password2?.message}
        />
        <Button type="submit" variant="contained">
          Send
        </Button>
      </Stack>
    </form>
  );
};

export const MissingResetPasswordParams: FC = () => {
  return (
    <Stack>
      <Typography variant="h6">Invalid request</Typography>
      <Typography variant="subtitle1">
        A parameter required to change your password is missing.
      </Typography>
      <Typography variant="subtitle1">
        Try opening your reset link again or copy and paste it into the browser.
      </Typography>
    </Stack>
  );
};
