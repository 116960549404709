import { Box, CircularProgress, SxProps, Theme } from '@mui/material';
import React, { FC, ReactNode } from 'react';

const LoadingSpinner: FC<{
  sx?: SxProps<Theme>;
  spinnerSize: number;
  containerSize: number;
}> = ({ sx, spinnerSize, containerSize }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: containerSize,
        flex: 1,
        ...sx,
      }}
    >
      <CircularProgress size={spinnerSize} />
    </Box>
  );
};

export interface WithLoadingProps {
  isLoading: boolean;
  children?: ReactNode;
  sx?: SxProps<Theme>;
  spinnerSize?: number;
  containerSize?: number;
}

/**
 * Conditionally renders a child or a spinner depending on the value of `isLoading`
 *
 * @param isLoading Condition to use to determine rendering
 * @param sx MUI sx styling prop passed to the container of the spinner
 * @param spinnerSize Size of the spinner, defaults to 40px
 * @param containerSize Size of the container, defaults to 300px
 * @param children React component to render
 */
export const WithLoading: FC<WithLoadingProps> = ({
  isLoading,
  sx,
  children,
  spinnerSize = 40,
  containerSize = 300,
}) => {
  return isLoading ? (
    <LoadingSpinner
      sx={sx}
      spinnerSize={spinnerSize}
      containerSize={containerSize}
    />
  ) : (
    <>{children}</>
  );
};
