import type { APIFunction, GenericErrorResponse } from '../http-common';
import { apiClient } from '../http-common';
import {
  LoginRequest,
  LoginResponse,
  UserCredentials,
  UserTokensWithUserData,
} from '../../types';

export const fetchJWTLogin: APIFunction<
  UserCredentials,
  UserTokensWithUserData
> = async ({ username, password }) => {
  try {
    const response = await apiClient.post<LoginResponse>('auth/login/', {
      email: username,
      password,
    } as LoginRequest);

    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
      user: response.data.user,
    };
  } catch (err: unknown) {
    const axiosError = err as GenericErrorResponse;
    const { data } = axiosError.response ?? {};

    throw data ?? [];
  }
};
