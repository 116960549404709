import React, {
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Alert } from '../types';
import { FloatingAlertContainer } from '../../components/Alert/Alert';
import { useLocation } from 'react-router-dom';
import { AlertColor } from '@mui/material';

export interface AlertOptions {
  dismissible?: boolean;
  dismissOnLocationChange?: boolean;
  description?: ReactNode;
  timeoutToDismiss?: number;
}

export interface AlertProviderContext {
  alerts: Alert[];
  addAlert: (
    title: string,
    severity: AlertColor,
    options?: AlertOptions
  ) => void;
  dismissAlert: (id: string) => void;
}

const alertProviderInitialState = {
  alerts: [],
  addAlert: (
    title: string,
    severity: AlertColor,
    options?: AlertOptions
  ): void => {
    /* */
  },
  dismissAlert: (_: string): void => {
    /* */
  },
};

export const AlertContext = createContext<AlertProviderContext>(
  alertProviderInitialState
);

export const AlertProvider: FC<PropsWithChildren> = ({ children }) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const location = useLocation();

  const dismissAlert = useCallback(
    (id: string) => {
      setAlerts((currentAlerts) => currentAlerts.filter((a) => a.id !== id));
    },
    [setAlerts]
  );

  const addAlert = useCallback(
    (
      title: string,
      severity: AlertColor,
      {
        dismissible = true,
        dismissOnLocationChange = true,
        description,
        timeoutToDismiss,
      }: AlertOptions = {}
    ) => {
      const alertId = Date.now().toString();
      setAlerts((currentAlerts) => [
        {
          id: alertId,
          severity: severity,
          title: title,
          description,
          dismissable: dismissible,
          dismissOnLocationChange,
        },
        ...currentAlerts,
      ]);
      if (timeoutToDismiss) {
        setTimeout(() => {
          dismissAlert(alertId);
        }, timeoutToDismiss);
      }
    },
    [dismissAlert]
  );

  useEffect(() => {
    setAlerts((currentAlerts) =>
      currentAlerts.filter((a) => !a.dismissOnLocationChange)
    );
  }, [location]);

  const context = useMemo(
    () => ({
      alerts,
      addAlert,
      dismissAlert,
    }),
    [alerts, addAlert, dismissAlert]
  );

  return (
    <AlertContext.Provider value={context}>
      <FloatingAlertContainer alerts={alerts} />
      {children}
    </AlertContext.Provider>
  );
};
