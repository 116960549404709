import React, { FC, useEffect, useState } from 'react';
import useAlert from '../../shared/hooks/useAlert';
import { WithLoading } from '../LoadingSpinner/LoadingSpinner';
import { Button, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { fetchAccessCodeByTrailerId } from '../../shared/api/trailers';
import { AxiosError } from 'axios';
import { TrailerAccessCode } from '../../shared/types';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export interface ViewAccessCodeButtonProps {
  trailerId: string;
  accessCode?: string;
  onSuccess?: (res: TrailerAccessCode) => void;
  onError?: () => void;
  onHide?: () => void;
  buttonVariant?: 'outlined' | 'text' | 'contained';
}

/**
 * Button that contains the logic for retrieving an Access Code.
 *
 * If the accessCode prop is passed then it's displayed as the retrieved one
 * and no fetching occurs. This is useful if the Access Code was retrieved using
 * the UnlockTrailerButton component.
 *
 * @param trailerId The trailerId for which to retrieve an Access Code
 * @param accessCode Access Code to use instead of fetching
 * @param onSuccess Callback that is called on success with TrailerAccessCode
 * @param onError Callback that is called on error
 * @param onHide Callback that is called when hiding the secret
 * @param buttonVariant Style of the button to use, defaults to outlined
 */
export const ViewAccessCodeButton: FC<ViewAccessCodeButtonProps> = ({
  trailerId,
  accessCode,
  onSuccess,
  onError,
  onHide,
  buttonVariant = 'outlined',
}) => {
  const [acFetchStatus, setACFetchStatus] = useState<
    'initial' | 'fetching' | 'fetched'
  >(accessCode ? 'fetched' : 'initial');
  const { addAlert } = useAlert();

  const { data } = useQuery(
    ['access_code', trailerId],
    () => fetchAccessCodeByTrailerId(trailerId),
    {
      enabled: acFetchStatus === 'fetching',
      cacheTime: 0,
      refetchInterval: 1000 * 60,
      onError: (err: AxiosError) => {
        if (err?.response?.status === 409) {
          addAlert('Trailer hardware not yet configured.', 'error');
        } else {
          addAlert(
            'An error occurred while fetching the access code.',
            'error'
          );
        }
        setACFetchStatus('initial');
        onError && onError();
      },
      onSuccess: (res: TrailerAccessCode) => {
        setACFetchStatus('fetched');
        onSuccess && onSuccess(res);
      },
    }
  );

  useEffect(() => {
    if (accessCode) {
      setACFetchStatus('fetched');
    } else {
      setACFetchStatus('initial');
    }
  }, [accessCode]);

  return (
    <WithLoading
      isLoading={acFetchStatus === 'fetching'}
      sx={{ minHeight: '40px' }}
      spinnerSize={20}
    >
      {acFetchStatus === 'initial' ? (
        <Button
          variant={buttonVariant}
          fullWidth
          onClick={(): void => setACFetchStatus('fetching')}
          sx={{ minHeight: '40px' }}
        >
          View Access Code
        </Button>
      ) : (
        <Button
          sx={{
            bgcolor: 'rgba(255,255,255,0.1)',
            py: 1,
            width: '100%',
            letterSpacing: 4,
            color: 'info.main',
            minHeight: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={(): void => {
            setACFetchStatus('initial');
            onHide && onHide();
          }}
        >
          <Typography variant="h6" fontFamily="monospace" sx={{ flex: 1 }}>
            {data?.access_code ?? accessCode ?? ''}
          </Typography>
          <VisibilityOffIcon sx={{ color: '#8b92a5', ml: 'auto', mr: 0 }} />
        </Button>
      )}
    </WithLoading>
  );
};
