import React from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function MenuListItem({
  icon,
  title,
  path,
}: {
  icon: JSX.Element;
  title: string;
  path: string;
}): JSX.Element {
  return (
    <ListItem disablePadding>
      <ListItemButton component={RouterLink} to={path}>
        <ListItemIcon sx={{ color: '#FFF' }}>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  );
}

export default MenuListItem;
