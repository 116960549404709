import React, { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import useAuth from '../../shared/hooks/useAuth';

const RequireAuth: FC = () => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/auth/signin" state={{ from: location }} replace />
  );
};

export default RequireAuth;
