import React, { ReactNode } from 'react';
import { Paper, PaperProps, SxProps, Theme } from '@mui/material';

type Props = {
  children: ReactNode;
  sx?: SxProps<Theme>;
} & PaperProps;

function SidebarBox({ children, sx, ...paperProps }: Props): JSX.Element {
  return (
    <Paper sx={{ p: 3, mb: 2, ...sx }} {...paperProps}>
      {children}
    </Paper>
  );
}

export default SidebarBox;
