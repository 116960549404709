import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';

/**
 * Takes a value and a delay in ms.
 * Every time this function is called within the `delay` (resetting it on each call) with potentially different `value`
 * parameters it will return the initial value.
 * After `delay` has passed and no more calls to this function have been made, the last `value` passed to this
 * function will be returned.
 * @param value Any value
 * @param delay Number in milliseconds
 */
export const useDebouncedState = <T>(value: T, delay = 500): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const debouncedSetter = useMemo(
    () =>
      debounce((v: T) => {
        setDebouncedValue(v);
      }, delay),
    [setDebouncedValue, delay]
  );

  useEffect(() => {
    debouncedSetter(value);
  }, [value, debouncedSetter]);

  return debouncedValue;
};
