import React, { FC, Fragment } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuListItem from './MenuListItem';

import { DRAWER_WIDTH, MAIN_MENU_ITEMS } from '../../shared/constants';
import useAuth from '../../shared/hooks/useAuth';
import { TrailerDriverAssignmentDialog } from '../TrailerDriverAsssignmentDialog/TrailerDriverAssignmentDialog';
import useModal from '../../shared/hooks/useModal';
import { DrawerMenuItem, UserType } from '../../shared/types';
import { hasMatchingRole } from '../../shared/utils/roleUtils';

type HandleDrawerClose = () => void;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const DrawerUser = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(10),
  ...theme.mixins.toolbar,
  justifyContent: 'center',
  padding: theme.spacing(0, 3),
}));

const DrawerLogout = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: 'auto',
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  minHeight: '80px',
  borderTop: '1px solid #8B92A5',
}));

const getDrawerMenuItemsForUser = (userType?: UserType[]): DrawerMenuItem[] => {
  if (!userType) return [];
  return MAIN_MENU_ITEMS.filter((menuItem) =>
    menuItem.users.find((mi) => userType.includes(mi))
  );
};

function DrawerMenu({
  open,
  handleDrawerClose,
}: {
  open: boolean;
  handleDrawerClose: HandleDrawerClose;
}): JSX.Element {
  const theme = useTheme();
  const { logout, userData, activeCompany } = useAuth();
  const assignmentModal = useModal();

  function handleLogout(): void {
    logout();
  }

  return (
    <Drawer
      sx={{
        display: 'flex',
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
      data-testid="drawermenu"
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? (
            <ChevronLeftIcon sx={{ color: '#FFF' }} />
          ) : (
            <ChevronRightIcon sx={{ color: '#FFF' }} />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <DrawerUser>
        <Typography variant="h6" sx={{ mb: 0.5 }}>
          {`${userData?.first_name ?? ''} ${userData?.last_name ?? ''}`}
        </Typography>
        <Typography variant="body2" color="#8B92A5" sx={{ mb: 0.5 }}>
          {userData?.email ?? ''}
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 3.5 }}>
          {activeCompany ? activeCompany.name : ''}
        </Typography>
        {activeCompany ? (
          <TemporaryAuthorizationButton onClick={assignmentModal.open} />
        ) : null}
      </DrawerUser>

      {activeCompany ? <DrawerMenuItems /> : null}
      <ChangeCompanyButton />
      <DrawerLogout sx={{ mt: 0 }}>
        <Divider />
        <Button variant="text" sx={{ fontSize: 16 }} onClick={handleLogout}>
          <LogoutIcon fontSize="small" sx={{ mr: 2, color: '#FFF' }} /> Log Out
        </Button>
      </DrawerLogout>
      {assignmentModal.isOpen ? (
        <TrailerDriverAssignmentDialog
          isOpen={assignmentModal.isOpen}
          onClose={assignmentModal.close}
        />
      ) : null}
    </Drawer>
  );
}

const ChangeCompanyButton: FC = () => {
  const { setActiveCompany, activeCompany, userData } = useAuth();

  if (!activeCompany || userData?.companies.length === 1) return <Fragment />;

  return (
    <Box sx={{ mb: 2, mt: 'auto', mx: 3 }}>
      <Button
        onClick={(): void => setActiveCompany(undefined)}
        variant="outlined"
        fullWidth
      >
        Change Company
      </Button>
    </Box>
  );
};

const DrawerMenuItems: FC = () => {
  const { activeCompany } = useAuth();
  return (
    <List sx={{ pl: 3, pr: 3, mt: 2, mb: 'auto' }}>
      {getDrawerMenuItemsForUser(activeCompany?.role).map((menuItem, i) => (
        <MenuListItem
          key={i}
          title={menuItem.title}
          icon={menuItem.icon}
          path={menuItem.path}
        />
      ))}
    </List>
  );
};

interface TemporaryAuthorizationButtonProps {
  onClick: () => void;
}

const TemporaryAuthorizationButton: FC<TemporaryAuthorizationButtonProps> = ({
  onClick,
}) => {
  const { activeCompany } = useAuth();
  return (
    <>
      {hasMatchingRole(
        ['DISPATCHER', 'MANAGER', 'OWNER', 'SUPERUSER'],
        activeCompany?.role
      ) ? (
        <Button
          variant="contained"
          sx={{ width: '100%', mb: 4 }}
          onClick={onClick}
        >
          Temporarily Authorize Driver
        </Button>
      ) : null}
    </>
  );
};

export default DrawerMenu;
