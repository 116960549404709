import React, { FC, useState } from 'react';
import DetailLayout from '../../layouts/DetailLayout/DetailLayout';
import { Box, Button, TextField } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { fetchPaginatedTrailers } from '../../shared/api/trailers';
import { Trailer } from '../../shared/types';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link as RouterLink } from 'react-router-dom';
import useModal from '../../shared/hooks/useModal';
import TrailerModal from '../../components/TrailerModal/TrailerModal';
import { usePaginatedQueryWithSearch } from '../../shared/hooks/usePaginatedQueryWithSearch';
import useAuth from '../../shared/hooks/useAuth';
import { hasMatchingRole } from '../../shared/utils/roleUtils';

const TrailerList: FC = () => {
  const { activeCompany } = useAuth();
  const userRoles = activeCompany?.role;
  const [search, setSearch] = useState('');

  const { isLoading, items, totalRowCount, setPage } =
    usePaginatedQueryWithSearch('trailers', fetchPaginatedTrailers, search);

  const { isOpen, open, close } = useModal();

  if (isLoading) return <span>Loading...</span>;

  const tableColumns: GridColDef[] = [
    {
      field: 'license_plate',
      headerName: 'LICENSE ID',
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <LocalShippingIcon fontSize="small" />
          {params.value}
        </Box>
      ),
    },
    { field: 'license_state', headerName: 'STATE', flex: 0.3 },
    {
      field: 'trailer_id',
      headerName: '',
      renderCell: (params: GridRenderCellParams<string>) => (
        <Button
          variant="outlined"
          component={RouterLink}
          to={`${params.value}`}
        >
          See Details
        </Button>
      ),
      sortable: false,
      minWidth: 150,
    },
  ];

  return (
    <DetailLayout
      title="Trailers"
      subtitle={`${activeCompany?.name} Trailers`}
      actionLabel={
        hasMatchingRole(['SUPERUSER', 'OWNER', 'MANAGER'], userRoles) ? (
          <>
            Add New Trailer <AddIcon fontSize="small" sx={{ ml: 1 }} />
          </>
        ) : undefined
      }
      action={
        hasMatchingRole(['SUPERUSER', 'OWNER', 'MANAGER'], userRoles)
          ? open
          : undefined
      }
      hasBackButton={false}
      sx={{ height: '70vh' }}
    >
      <Box sx={{ display: 'flex', alignItems: 'end', mb: 2 }}>
        <SearchIcon sx={{ mr: 1, my: 0.5, color: '#8B92A5' }} />
        <TextField
          label="Search"
          variant="standard"
          fullWidth
          sx={{ '& label': { typography: 'subtitle1' } }}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
      </Box>
      <DataGrid
        columns={tableColumns}
        pageSize={30}
        paginationMode="server"
        rowCount={totalRowCount}
        rowsPerPageOptions={[30]}
        onPageChange={setPage}
        rows={items}
        loading={isLoading}
        getRowId={(r: Trailer): string => r.trailer_id}
        disableColumnSelector
        disableColumnMenu
        disableColumnFilter
        disableSelectionOnClick
      />
      {isOpen ? <TrailerModal isOpen={isOpen} close={close} /> : null}
    </DetailLayout>
  );
};

export default TrailerList;
