import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

type Props = {
  children: ReactNode;
};

function Sidebar({ children }: Props): JSX.Element {
  return (
    <Box
      sx={{
        flex: '0 1 320px',
      }}
    >
      {children}
    </Box>
  );
}

export default Sidebar;
