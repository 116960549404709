import React, { FC, Fragment, useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  styled,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DialpadIcon from '@mui/icons-material/Dialpad';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CachedIcon from '@mui/icons-material/Cached';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Sidebar from '../../components/Sidebar/Sidebar';
import SidebarBox from '../../components/SidebarBox/SidebarBox';
import DetailLayout from '../../layouts/DetailLayout/DetailLayout';
import { Outlet, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchTrailerById } from '../../shared/api/trailers';
import { DetailedAssignment, Trailer } from '../../shared/types';
import { WithLoading } from '../../components/LoadingSpinner/LoadingSpinner';
import useAlert from '../../shared/hooks/useAlert';
import { TrailerDriverAssignmentDialog } from '../../components/TrailerDriverAsssignmentDialog/TrailerDriverAssignmentDialog';
import useModal from '../../shared/hooks/useModal';
import TrailerModal from '../../components/TrailerModal/TrailerModal';
import { ViewAccessCodeButton } from '../../components/ViewAccessCodeButton/ViewAccessCodeButton';
import { UnlockTrailerButton } from '../../components/UnlockTrailerButton/UnlockTrailerButton';
import { TrailerStatusIndicator } from '../../components/TrailerStatusIndicator/TrailerStatusIndicator';
import { useTrailerStatus } from '../../shared/hooks/useTrailerStatus';
import useAuth from '../../shared/hooks/useAuth';
import { hasMatchingRole } from '../../shared/utils/roleUtils';
import { TabPanel } from '../../components/TabUtils/TabUtils';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { usePaginatedQueryWithSearch } from '../../shared/hooks/usePaginatedQueryWithSearch';
import { fetchPaginatedAssignments } from '../../shared/api/assignments/assignments';
import { formatDate } from '../../shared/utils/DateTimeFormat';
import { useTrailerPASStatus } from '../../shared/hooks/useTrailerPASStatus';
import { string } from 'yup';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  border: '1px solid #232C35',
  borderBottom: '0 none',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),

  '&:first-of-type': {
    borderRadius: '8px 8px 0 0',
  },

  '&:last-child': {
    borderBottom: '1px solid #232C35',
    borderRadius: '0 0 8px 8px',
  },
}));

type TrailerParams = {
  trailerId: string;
};

function TrailerDetailPage(): JSX.Element {
  const { activeCompany } = useAuth();
  const userRoles = activeCompany?.role;
  const { trailerId } = useParams<TrailerParams>();
  const trailerModal = useModal();
  const assignmentModal = useModal();

  const { data, isLoading, error } = useQuery(['trailer', trailerId], () =>
    fetchTrailerById(trailerId)
  );

  const { addAlert } = useAlert();
  useEffect(() => {
    const isFetchedDataEmpty = !isLoading && !data;

    if (error || isFetchedDataEmpty) {
      addAlert('Unable to fetch Trailer detail', 'error');
    }
  }, [addAlert, error, data, isLoading]);

  const [currentTab, setCurrentTab] = useState<
    'detail' | 'assignments' | 'past_assignments'
  >('detail');

  return (
    <DetailLayout
      title="Trailer Info"
      actionLabel={
        hasMatchingRole(['SUPERUSER', 'OWNER', 'MANAGER'], userRoles)
          ? 'Edit Information'
          : undefined
      }
      action={
        hasMatchingRole(['SUPERUSER', 'OWNER', 'MANAGER'], userRoles)
          ? trailerModal.open
          : undefined
      }
    >
      <Box
        sx={{
          display: { md: 'flex', sm: 'block' },
          gap: 3,
        }}
      >
        <Sidebar>
          <SidebarBox>
            <Button
              variant="outlined"
              sx={{ width: '100%' }}
              onClick={assignmentModal.open}
              type="button"
            >
              Create Assignment
            </Button>
          </SidebarBox>
          <TrailerStatusCard trailerId={trailerId} />
          <TrailerAccessCodeCard trailerId={trailerId} />
        </Sidebar>
        <Outlet />
        <Box
          sx={{
            flex: '3.25 1 auto',
          }}
        >
          <Tabs
            value={currentTab}
            onChange={(
              e,
              value: 'detail' | 'assignments' | 'past_assignments'
            ): void => setCurrentTab(value)}
          >
            <Tab label="Information" value="detail" />
            <Tab label="Active and Upcoming Assignments" value="assignments" />
            <Tab label="Past Assignments" value="past_assignments" />
          </Tabs>
          <TabPanel hidden={currentTab !== 'detail'}>
            <WithLoading isLoading={isLoading && !data}>
              <TrailerDetailCard trailer={data} />
            </WithLoading>
          </TabPanel>
          <TabPanel hidden={currentTab !== 'assignments'}>
            <TrailerAssignments trailerId={trailerId} />
          </TabPanel>
          <TabPanel hidden={currentTab !== 'past_assignments'}>
            <TrailerAssignments trailerId={trailerId} historic={true} />
          </TabPanel>
        </Box>
      </Box>
      {trailerModal.isOpen ? (
        <TrailerModal
          isOpen={trailerModal.isOpen}
          close={trailerModal.close}
          trailerData={data}
        />
      ) : null}
      {assignmentModal.isOpen ? (
        <TrailerDriverAssignmentDialog
          isOpen={assignmentModal.isOpen}
          onClose={assignmentModal.close}
          trailerId={trailerId}
        />
      ) : null}
    </DetailLayout>
  );
}

const TrailerAssignments: FC<{ trailerId?: string; historic?: boolean }> = ({
  trailerId,
  historic = false,
}) => {
  const { isLoading, items, totalRowCount, setPage } =
    usePaginatedQueryWithSearch('historic-assignments', (page) =>
      fetchPaginatedAssignments(page, {
        ...(historic ? { historic: true } : { current: true }),
        trailer_id: trailerId,
      })
    );
  const tableColumns: GridColDef[] = [
    {
      field: 'driver_name',
      headerName: 'Driver',
      flex: 1,
      valueGetter: (params): string => {
        const { driverFirstName, driverLastName } =
          params.row as DetailedAssignment;
        return `${driverFirstName} ${driverLastName}`;
      },
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <PeopleAltIcon fontSize="small" />
          {params.value}
        </Box>
      ),
    },
    {
      field: 'startTime',
      headerName: 'Start Time',
      flex: 0.3,
      valueGetter: (p: GridValueGetterParams<Date>): string =>
        p.value ? formatDate(p.value) : '',
    },
    {
      field: 'endTime',
      headerName: 'End Time',
      flex: 0.3,
      valueGetter: (p: GridValueGetterParams<Date>): string =>
        p.value ? formatDate(p.value) : '',
    },
  ];

  return (
    <Box sx={{ height: '70vh' }}>
      <DataGrid
        columns={tableColumns}
        pageSize={30}
        paginationMode="server"
        rowCount={totalRowCount}
        onPageChange={setPage}
        rowsPerPageOptions={[30]}
        rows={items}
        loading={isLoading}
        getRowId={(a: DetailedAssignment): string => a.assignmentId}
        disableColumnSelector
        disableColumnMenu
        disableColumnFilter
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
          },
        }}
      />
    </Box>
  );
};

const TrailerStatusCard: FC<{ trailerId?: string }> = ({ trailerId }) => {
  const { trailerStatusData, isTrailerStatusLoading } =
    useTrailerStatus(trailerId);
  const {
    doorStatus,
    lastUpdateLabel,
    powerStatus,
    isEnclosureOpen,
    isOnBatteryPower,
    refresh,
  } = useTrailerPASStatus(trailerId, trailerStatusData?.online);

  const { addAlert } = useAlert();
  useEffect(() => {
    if (isOnBatteryPower) {
      addAlert('Operating on battery power', 'warning', {
        description: 'Notify driver to connect enclosure to external power.',
      });
    }
  }, [addAlert, isOnBatteryPower]);

  return (
    <SidebarBox data-testid="trailer-status-card">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 1.5,
        }}
      >
        {isEnclosureOpen ? (
          <LockOpenIcon fontSize="large" />
        ) : (
          <LockIcon fontSize="large" />
        )}
        <Typography variant="body1">{doorStatus}</Typography>
      </Box>
      <TrailerStatusIndicator
        sx={{ color: '#8b92a5', mr: 0, ml: 'auto', mb: 1.5 }}
        isOnline={trailerStatusData?.online}
        isTrailerStatusLoading={isTrailerStatusLoading}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          flexWrap: 'wrap',
          mb: 1,
        }}
      >
        <Typography fontSize="14px">{powerStatus}</Typography>
        {isOnBatteryPower ? (
          <Tooltip title="Notify driver to connect to external power">
            <ReportProblemIcon fontSize="small" />
          </Tooltip>
        ) : null}
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          justifyContent: 'space-between',
          mb: 1,
        }}
      >
        <Typography variant="subtitle1">{lastUpdateLabel}</Typography>
        <Tooltip title="Refresh now">
          <IconButton
            aria-label="refresh"
            onClick={(): void => {
              refresh();
            }}
          >
            <CachedIcon fontSize="small" sx={{ color: '#8b92a5' }} />
          </IconButton>
        </Tooltip>
      </Box>

      {trailerId && trailerStatusData?.online ? (
        <UnlockTrailerButton
          trailerId={trailerId}
          autoreset
          onSuccess={refresh}
        />
      ) : null}
    </SidebarBox>
  );
};

const TrailerAccessCodeCard: FC<{ trailerId?: string }> = ({ trailerId }) => {
  return (
    <SidebarBox data-testid="accesscodecard">
      <DialpadIcon fontSize="large" sx={{ mb: 1.5 }} />
      <Typography variant="body2" sx={{ color: '#8B92A5', mb: 2 }}>
        PIN
      </Typography>
      {trailerId ? (
        <ViewAccessCodeButton trailerId={trailerId} buttonVariant="contained" />
      ) : null}
    </SidebarBox>
  );
};

const TrailerDetailCard: FC<{ trailer?: Trailer }> = ({ trailer }) => {
  if (!trailer) return <Fragment />;

  return (
    <List disablePadding>
      <StyledListItem>
        <ListItemText primary="License Plate" />
        <ListItemText
          primary={trailer.license_plate}
          sx={{ textAlign: 'right' }}
        />
      </StyledListItem>
      <StyledListItem>
        <ListItemText primary="License Plate State" />
        <ListItemText
          primary={trailer.license_state}
          sx={{ textAlign: 'right' }}
        />
      </StyledListItem>
      <StyledListItem>
        <ListItemText primary="Device ID" />
        <ListItemText primary={trailer.device_id} sx={{ textAlign: 'right' }} />
      </StyledListItem>
    </List>
  );
};

export default TrailerDetailPage;
