import React from 'react';
import { Box, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { DRAWER_WIDTH } from '../../shared/constants';
import logo from '../../assets/images/trailerpas-logo.png';
import useAuth from '../../shared/hooks/useAuth';

type HandleDrawerOpen = () => void;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  // minHeight: '64px',
  backgroundColor: theme.palette.background.paper,
  boxShadow: 'none',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: `${DRAWER_WIDTH}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledUser = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    visibility: 'hidden',
  },
}));

function Header({
  open,
  handleDrawerOpen,
}: {
  open: boolean;
  handleDrawerOpen: HandleDrawerOpen;
}): JSX.Element {
  const { userData, activeCompany } = useAuth();

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon sx={{ color: '#FFF' }} />
            </IconButton>
            <img src={logo} width="145" alt="Allied Risk - TrailerPAS Logo" />
          </Box>
          <StyledUser>
            <Stack>
              <Typography variant="body1">
                Welcome{userData ? `, ${userData.first_name}` : ''}
              </Typography>
              <Typography variant="subtitle1">
                {activeCompany ? activeCompany.name : ''}
              </Typography>
            </Stack>
            <AccountCircleIcon sx={{ ml: 2, color: '#FFF' }} />
          </StyledUser>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
