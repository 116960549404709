import React, { FC } from 'react';
import { Alert, AlertTitle, IconButton, Stack } from '@mui/material';
import { Alert as AppAlert } from '../../shared/types';
import CloseIcon from '@mui/icons-material/Close';
import useAlert from '../../shared/hooks/useAlert';

export type AlertProps = AppAlert;

export const FloatingAlert: FC<AlertProps> = ({
  id,
  title,
  description,
  dismissable,
  severity,
}) => {
  const { dismissAlert } = useAlert();
  return (
    <Alert
      severity={severity}
      action={
        dismissable ? (
          <IconButton
            aria-label="close"
            color="inherit"
            size={'small'}
            onClick={(): void => {
              dismissAlert(id);
            }}
          >
            <CloseIcon
              fontSize="inherit"
              color={severity}
              sx={{ color: severity }}
              style={{ color: severity }}
            />
          </IconButton>
        ) : undefined
      }
      sx={{}}
    >
      <AlertTitle
        sx={{
          color: 'inherit',
          marginBottom: description ? '0.35em' : 0,
        }}
      >
        {title}
      </AlertTitle>
      {!!description && description}
    </Alert>
  );
};

export const FloatingAlertContainer: FC<{ alerts: AppAlert[] }> = ({
  alerts,
}) => {
  return (
    <Stack
      sx={{
        zIndex: 'snackbar',
        position: 'absolute',
        top: '40px',
        right: 0,
        left: 0,
        marginX: 'auto',
        maxWidth: '800px',
        gap: 2,
        paddingX: 2,
      }}
    >
      {alerts.map((a) => (
        <FloatingAlert key={a.id} {...a} />
      ))}
    </Stack>
  );
};
