import React, { FC } from 'react';
import { Button } from '@mui/material';
import { recoverPassword } from '../../shared/api/auth/auth';
import { useMutation } from 'react-query';
import useAlert from '../../shared/hooks/useAlert';

export interface ResetPasswordButtonProps {
  email: string;
}

export const ResetPasswordButton: FC<ResetPasswordButtonProps> = ({
  email,
}) => {
  const { addAlert } = useAlert();
  const sendMutation = useMutation(() => recoverPassword(email), {
    onSuccess: () => {
      addAlert('Reset email sent', 'success');
    },
    onError: () => {
      addAlert('An error has occurred', 'error');
    },
  });

  return (
    <Button
      onClick={() => sendMutation.mutate()}
      sx={{ color: 'primary.main' }}
    >
      Send Reset Password
    </Button>
  );
};
