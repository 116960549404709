import React, { FC, useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export interface ConfirmationDialogProps {
  title: string;
  description: string | JSX.Element;
  onConfirmation: () => void;
  confirmationLabel: JSX.Element;
  onCancel: () => void;
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  title,
  description,
  onConfirmation,
  confirmationLabel,
  onCancel,
}) => {
  const [isConfirmationExecuted, setIsConfirmationExecuted] = useState(false);

  const executeConfirmation = useCallback(() => {
    onConfirmation();
    setIsConfirmationExecuted(true);
  }, [onConfirmation, setIsConfirmationExecuted]);

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      fullWidth
      maxWidth="xs"
      data-testid="confirmation-dialog"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText typography="subtitle1">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ mx: 2, mb: 1 }}>
        <Button
          variant="outlined"
          sx={{ ml: 0, mr: 'auto' }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={isConfirmationExecuted}
          onClick={executeConfirmation}
        >
          {confirmationLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
