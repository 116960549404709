import React, { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { YardJockey } from '../../shared/types';
import { ModalParams } from '../../shared/hooks/useModal';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useAlert from '../../shared/hooks/useAlert';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import {
  addYardJockey,
  deleteYardJockey,
  editYardJockey,
} from '../../shared/api/yardjockeys';

export type YardJockeyModalProps = ModalParams & {
  yardJockeyData?: YardJockey;
};

const yardJockeySchema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  email: yup.string().email().required('Email is required'),
});

export const YardJockeyModal: FC<YardJockeyModalProps> = ({
  isOpen,
  close,
  yardJockeyData,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<YardJockey>({
    defaultValues: {
      first_name: '' ?? yardJockeyData?.first_name,
      last_name: '' ?? yardJockeyData?.last_name,
      email: '' ?? yardJockeyData?.email,
    },
    resolver: yupResolver(yardJockeySchema),
  });

  const [apiError, setApiError] = useState<string>();
  const [modalState, setModalState] = useState<'edit' | 'confirmation'>('edit');
  const isEditing = !!yardJockeyData;

  const closeCleanup = (): void => {
    reset();
    setApiError('');
    close();
  };

  useEffect(() => {
    reset(yardJockeyData);
  }, [reset, yardJockeyData]);

  const { addAlert } = useAlert();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const addMutation = useMutation(
    (jockey: YardJockey) => addYardJockey(jockey),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('jockeys');
        addAlert('Yard Jockey created successfully', 'success');
        closeCleanup();
      },
      onError: () => setApiError('An error has occurred'),
    }
  );
  const editMutation = useMutation(
    (jockey: YardJockey) => editYardJockey(jockey),
    {
      onSuccess: async () => {
        const queryKey = ['jockey', yardJockeyData?.user_id.toString()];
        await queryClient.invalidateQueries(queryKey);
        addAlert('Yard Jockey updated successfully', 'success');
        closeCleanup();
      },
      onError: () => {
        setApiError('An error has occurred');
        setModalState('edit');
      },
    }
  );

  const deleteMutation = useMutation(
    () => deleteYardJockey(yardJockeyData?.user_id.toString()),
    {
      onSuccess: async () => {
        addAlert('Successfully deleted Yard Jockey.', 'success', {
          dismissOnLocationChange: false,
          timeoutToDismiss: 5000,
        });
        closeCleanup();
        navigate('/jockeys');
        await queryClient.invalidateQueries('jockeys');
      },
      onError: () => {
        setApiError('An error has occurred');
        setModalState('edit');
      },
    }
  );

  const submitHandler: SubmitHandler<YardJockey> = (data): void => {
    if (data.user_id) {
      editMutation.mutate(data);
    } else {
      addMutation.mutate(data);
    }
  };

  return modalState === 'edit' ? (
    <Dialog open={isOpen} onClose={(): null => null} maxWidth="sm" fullWidth>
      <form
        noValidate
        autoComplete="off"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitHandler)}
      >
        <DialogTitle>
          {isEditing ? 'Edit ' : 'Add New '}
          Yard Jockey
        </DialogTitle>
        <DialogContent>
          {apiError ? (
            <Box mb={3}>
              <Alert severity="error" variant="filled">
                {apiError}
              </Alert>
            </Box>
          ) : null}
          <Stack mt={2} spacing={4}>
            <TextField
              {...register('first_name')}
              id="first_name"
              label="First Name"
              type="text"
              autoComplete="off"
              fullWidth
              error={!!errors.first_name}
              helperText={errors.first_name?.message}
            />
            <TextField
              {...register('last_name')}
              id="last_name"
              label="Last Name"
              type="text"
              autoComplete="off"
              fullWidth
              error={!!errors.last_name}
              helperText={errors.last_name?.message}
            />
            <TextField
              {...register('email')}
              id="email"
              label="Email"
              type="text"
              autoComplete="off"
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ mx: 2, mb: 1 }}>
          {isEditing ? (
            <Button
              sx={{ ml: 0, mr: 'auto', color: 'error.main' }}
              onClick={(): void => setModalState('confirmation')}
            >
              Delete Jockey
            </Button>
          ) : null}
          <Button onClick={closeCleanup}>Cancel</Button>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  ) : (
    <ConfirmationDialog
      title="Delete Jockey?"
      description={
        <>
          Are you sure you want to delete this Jockey? All information will be
          lost.
        </>
      }
      onConfirmation={(): void => deleteMutation.mutate()}
      confirmationLabel={<>Yes, Delete</>}
      onCancel={closeCleanup}
    />
  );
};
