import React, { FC, useCallback } from 'react';
import { usePaginatedQueryWithSearch } from '../../shared/hooks/usePaginatedQueryWithSearch';
import { fetchPaginatedAssignments } from '../../shared/api/assignments/assignments';
import DetailLayout from '../../layouts/DetailLayout/DetailLayout';
import { Box, Button, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import useModal from '../../shared/hooks/useModal';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { isAfter, isBefore } from 'date-fns';
import { DetailedAssignment } from '../../shared/types';
import {
  TrailerDriverAssignmentDialog,
  TrailerDriverAssignmentDialogProps,
} from '../../components/TrailerDriverAsssignmentDialog/TrailerDriverAssignmentDialog';
import useAuth from '../../shared/hooks/useAuth';
import { formatDate } from '../../shared/utils/DateTimeFormat';

export const AssignmentList: FC = () => {
  const { activeCompany } = useAuth();
  const { isLoading, items, totalRowCount, setPage, currentPage } =
    usePaginatedQueryWithSearch('assignments', (page) =>
      fetchPaginatedAssignments(page, { current: '' })
    );

  const assignmentModal = useModal<TrailerDriverAssignmentDialogProps>();
  const openModalForAssignment = useCallback(
    (da: DetailedAssignment) => {
      assignmentModal.openWithProps({
        assignmentData: {
          id: da.assignmentId,
          driver_id: da.driverId,
          trailer_id: da.trailerId,
          load_id: da.loadId,
          start_time: da.startTime,
          end_time: da.endTime,
        },
        queryInvalidationKey: ['assignments', currentPage],
      });
    },
    [assignmentModal, currentPage]
  );

  if (isLoading) return <span>Loading...</span>;

  const tableColumns: GridColDef[] = [
    {
      field: 'driverName',
      headerName: 'Driver',
      flex: 0.4,
      valueGetter: (params): string => {
        const { driverFirstName, driverLastName } =
          params.row as DetailedAssignment;
        return `${driverFirstName} ${driverLastName}`;
      },
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <PeopleAltIcon fontSize="small" />
          {params.value}
        </Box>
      ),
    },
    {
      field: 'loadId',
      headerName: 'Load ID',
      flex: 0.3,
    },
    {
      field: 'trailerPlate',
      headerName: 'Trailer Plate',
      flex: 0.2,
    },
    {
      field: 'trailerPlateState',
      headerName: 'Trailer Plate State',
      flex: 0.2,
    },
    {
      field: 'status',
      headerName: 'Status',
      valueGetter: (
        p: GridValueGetterParams<Date, DetailedAssignment>
      ): string =>
        isAfter(new Date(), p.row.startTime) &&
        isBefore(new Date(), p.row.endTime)
          ? 'Active'
          : 'Pending',
      minWidth: 120,
    },
    {
      field: 'startTime',
      headerName: 'Start Time',
      flex: 0.2,
      valueGetter: (p: GridValueGetterParams<Date>): string =>
        p.value ? formatDate(p.value) : '',
    },
    {
      field: 'endTime',
      headerName: 'End Time',
      flex: 0.2,
      valueGetter: (p: GridValueGetterParams<Date>): string =>
        p.value ? formatDate(p.value) : '',
    },
    {
      field: 'assignmentId',
      headerName: '',
      renderCell: (
        params: GridRenderCellParams<string, DetailedAssignment>
      ) => (
        <Button
          variant="outlined"
          onClick={(): void => openModalForAssignment(params.row)}
        >
          Edit
        </Button>
      ),
      sortable: false,
      minWidth: 100,
    },
  ];

  return (
    <DetailLayout
      title="Assignments"
      subtitle={`${activeCompany?.name} Assignments`}
      hasBackButton={false}
      sx={{ height: '70vh' }}
      action={assignmentModal.open}
      actionLabel={
        <>
          New assignment <AddIcon fontSize="small" sx={{ ml: 1 }} />
        </>
      }
    >
      <Box sx={{ display: 'flex', alignItems: 'end', mb: 2 }}>
        <SearchIcon sx={{ mr: 1, my: 0.5, color: '#8B92A5' }} />
        <TextField
          label="Search"
          variant="standard"
          fullWidth
          sx={{ '& label': { typography: 'subtitle1' } }}
        />
      </Box>
      <DataGrid
        columns={tableColumns}
        pageSize={30}
        paginationMode="server"
        rowCount={totalRowCount}
        onPageChange={setPage}
        rowsPerPageOptions={[30]}
        rows={items}
        loading={isLoading}
        getRowId={(a: DetailedAssignment): string => a.assignmentId}
        disableColumnSelector
        disableColumnMenu
        disableColumnFilter
        disableSelectionOnClick
      />
      {assignmentModal.isOpen ? (
        <TrailerDriverAssignmentDialog
          onClose={assignmentModal.close}
          isOpen={assignmentModal.isOpen}
          {...assignmentModal.dynamicProps}
        />
      ) : null}
    </DetailLayout>
  );
};
