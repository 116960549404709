import { Box, Button, Paper, TextField, Tooltip } from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { AutocompleteSearchField } from '../../components/AutocompleteSearchField/AutocompleteSearchField';
import { searchTrailers } from '../../shared/api/trailers';
import { DetailedAssignment, Driver, Trailer } from '../../shared/types';
import { startOfDay, startOfHour, subWeeks } from 'date-fns';
import { searchDrivers } from '../../shared/api/drivers/drivers';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import DownloadIcon from '@mui/icons-material/Download';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { usePaginatedQueryWithSearch } from '../../shared/hooks/usePaginatedQueryWithSearch';
import {
  downloadAssignmentsAsCsv,
  fetchPaginatedAssignments,
} from '../../shared/api/assignments/assignments';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { formatDate } from '../../shared/utils/DateTimeFormat';
import { downloadResponseBlob } from '../../shared/utils/downloadResponseBlob';
import useAlert from '../../shared/hooks/useAlert';

const filtersToQueryParams = (filters: FilterForm) => ({
  ...(filters.driver_id ? { driver_id: filters.driver_id } : {}),
  ...(filters.trailer_id ? { trailer_id: filters.trailer_id } : {}),
  ...(filters.start_time
    ? { start_time: filters.start_time.toISOString() }
    : {}),
  ...(filters.end_time ? { end_time: filters.end_time.toISOString() } : {}),
});

export const AssignmentReports: FC = () => {
  const [filters, setFilters] = useState<FilterForm>({
    driver_id: '',
    trailer_id: '',
    start_time: subWeeks(startOfDay(new Date()), 2),
    end_time: startOfHour(new Date()),
  });
  const queryKey = [
    'assignment-reports-query',
    filters.trailer_id,
    filters.driver_id,
    filters.start_time?.toISOString(),
    filters.end_time?.toISOString(),
  ]
    .filter((v) => !!v) // Filter non-undefined
    .join('-');

  const { isLoading, items, totalRowCount, setPage } =
    usePaginatedQueryWithSearch(queryKey, (page) =>
      fetchPaginatedAssignments(page, filtersToQueryParams(filters))
    );

  const handleCSVDownload = useCallback(async () => {
    const csvBlob = await downloadAssignmentsAsCsv(
      filtersToQueryParams(filters)
    );
    downloadResponseBlob(
      csvBlob,
      `assignments-${
        filters.start_time ? formatDate(filters.start_time) : ''
      }-${filters.end_time ? formatDate(filters.end_time) : ''}`
    );
  }, [filters]);

  const wrappedOnDownloadCB = () => {
    handleCSVDownload().catch((err) =>
      addAlert('An error occurred while downloading the CSV file', 'error')
    );
  };

  const { addAlert } = useAlert();

  const tableColumns: GridColDef[] = [
    {
      field: 'driverName',
      headerName: 'Driver',
      flex: 0.4,
      valueGetter: (params): string => {
        const { driverFirstName, driverLastName } =
          params.row as DetailedAssignment;
        return `${driverFirstName} ${driverLastName}`;
      },
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <PeopleAltIcon fontSize="small" />
          {params.value}
        </Box>
      ),
    },
    {
      field: 'loadId',
      headerName: 'Load ID',
      flex: 0.3,
    },
    {
      field: 'trailerPlate',
      headerName: 'Trailer Plate',
      flex: 0.2,
    },
    {
      field: 'trailerPlateState',
      headerName: 'Trailer Plate State',
      flex: 0.2,
    },
    {
      field: 'startTime',
      headerName: 'Start Time',
      flex: 0.2,
      valueGetter: (p: GridValueGetterParams<Date>): string =>
        p.value ? formatDate(p.value) : '',
    },
    {
      field: 'endTime',
      headerName: 'End Time',
      flex: 0.2,
      valueGetter: (p: GridValueGetterParams<Date>): string =>
        p.value ? formatDate(p.value) : '',
    },
  ];

  return (
    <Box>
      <FilterBar
        onDownload={wrappedOnDownloadCB}
        initialFilters={filters}
        setFilters={setFilters}
      />
      <Box sx={{ height: '60vh', marginTop: 1 }}>
        <DataGrid
          columns={tableColumns}
          pageSize={30}
          paginationMode="server"
          rowCount={totalRowCount}
          onPageChange={setPage}
          rowsPerPageOptions={[30]}
          rows={items}
          loading={isLoading}
          getRowId={(a: DetailedAssignment): string => a.assignmentId}
          disableColumnSelector
          disableColumnMenu
          disableColumnFilter
          disableSelectionOnClick
        />
      </Box>
    </Box>
  );
};

interface FilterForm {
  trailer_id?: string;
  driver_id?: string;
  start_time?: Date;
  end_time?: Date;
}

interface FilterBarProps {
  initialFilters: FilterForm;
  setFilters: (f: FilterForm) => void;
  onDownload: () => void;
}

export const FilterBar: FC<FilterBarProps> = ({
  initialFilters,
  setFilters,
  onDownload,
}) => {
  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm<FilterForm>({
    defaultValues: initialFilters,
  });

  const [selectedTrailer, setSelectedTrailer] = useState<Trailer | null>(null);
  const [selectedDriver, setSelectedDriver] = useState<Driver | null>(null);
  const selectedStartTime = useWatch({ control, name: 'start_time' });

  useEffect(() => {
    const subscription = watch((data) => {
      setFilters(data);
    });
    return () => subscription.unsubscribe();
  }, [setFilters, watch]);

  return (
    <form>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          padding: 2,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <AutocompleteSearchField
          sx={{ flex: 0.3 }}
          getLabel={(t): string => t.license_plate}
          searchFn={searchTrailers}
          queryKey="trailers_search"
          onChange={(t): void => {
            setValue('trailer_id', t ? t.trailer_id : '');
            setSelectedTrailer(t);
          }}
          value={selectedTrailer}
          error={!!errors.trailer_id}
          helperText={errors.trailer_id?.message}
          fieldLabel="Trailer"
          isOptionEqualToValue={(option, value): boolean =>
            option.trailer_id === value.trailer_id
          }
          fullWidth={false}
        />

        <AutocompleteSearchField
          sx={{ flex: 0.3 }}
          getLabel={(d): string => `${d.first_name} ${d.last_name}`}
          searchFn={searchDrivers}
          queryKey={'drivers_search'}
          onChange={(d): void => {
            setValue('driver_id', d ? d.driver_id : '');
            setSelectedDriver(d);
          }}
          value={selectedDriver}
          error={!!errors.driver_id}
          helperText={errors.driver_id?.message}
          fieldLabel={'Driver'}
          isOptionEqualToValue={(option, value): boolean =>
            option.driver_id === value.driver_id
          }
          fullWidth={false}
        />

        <Controller
          name="start_time"
          control={control}
          render={({ field }): JSX.Element => (
            <MobileDateTimePicker
              label="Start time"
              {...field}
              disableFuture
              renderInput={(params): JSX.Element => (
                <TextField
                  {...params}
                  sx={{ flex: 0.2 }}
                  error={!!errors.start_time}
                  helperText={errors.start_time?.message}
                />
              )}
            />
          )}
        />

        <Controller
          name="end_time"
          control={control}
          render={({ field }): JSX.Element => (
            <MobileDateTimePicker
              label="End time"
              {...field}
              minDateTime={selectedStartTime}
              disableFuture
              renderInput={(params): JSX.Element => (
                <TextField
                  {...params}
                  sx={{ flex: 0.2 }}
                  error={!!errors.end_time}
                  helperText={errors.end_time?.message}
                />
              )}
            />
          )}
        />
        <Tooltip title="Click to download CSV file">
          <Button aria-label="Download" variant="outlined" onClick={onDownload}>
            <DownloadIcon />
          </Button>
        </Tooltip>
      </Paper>
    </form>
  );
};
