import React, { FC, Fragment, useEffect } from 'react';
import useAuth from '../../shared/hooks/useAuth';
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

const CompanySelectLayout = styled(Box)(({ theme }) => ({
  width: '25vw',
  margin: '0 auto',

  [theme.breakpoints.down('lg')]: {
    width: '50vw',
  },
  [theme.breakpoints.down('md')]: {
    width: '75vw',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const CompanyList: FC = () => {
  const { userData, activeCompany, setActiveCompany } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.removeQueries();
  }, [queryClient]);

  useEffect(() => {
    if (activeCompany) {
      navigate('/', { replace: true });
    }
  }, [activeCompany, navigate]);

  if (userData?.companies.length === 1) {
    setActiveCompany(userData.companies[0]);
    return <Fragment />;
  }

  return (
    <CompanySelectLayout>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Select Active Company
      </Typography>
      <Paper variant="outlined">
        <List disablePadding>
          {userData?.companies.map((c) => (
            <ListItemButton
              key={c.id}
              divider
              onClick={(): void => {
                setActiveCompany(c);
              }}
            >
              <ListItemText>{c.name}</ListItemText>
            </ListItemButton>
          ))}
        </List>
      </Paper>
    </CompanySelectLayout>
  );
};
