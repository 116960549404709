import {
  LastTrailerPASStatus,
  LastTrailerPASStatusResponse,
  Paginated,
  Trailer,
  TrailerAccessCode,
  TrailerStatus,
  UnlockResponse,
} from '../../types';
import { apiClient } from '../http-common';
import { AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';

export const fetchAllTrailers = (): Promise<Trailer[]> => {
  return apiClient
    .get<Trailer[]>('trailers/', { params: { all: '' } })
    .then((res) => {
      return res.data;
    });
};

export const searchTrailers = (search: string): Promise<Trailer[]> => {
  return apiClient
    .get<Paginated<Trailer>>('trailers/', { params: { search, page: 1 } })
    .then((res) => {
      return res.data.results;
    });
};

export const fetchPaginatedTrailers = (
  page = 1,
  search?: string
): Promise<Paginated<Trailer>> => {
  return apiClient
    .get<Paginated<Trailer>>('trailers/', { params: { page, search: search } })
    .then((res) => {
      return res.data;
    });
};

export const fetchTrailerById = (trailerId?: string): Promise<Trailer> => {
  if (!trailerId) {
    return Promise.reject('A trailerId is required');
  }

  return apiClient
    .get<Trailer>(`trailers/${trailerId}/`)
    .then((res: AxiosResponse<Trailer>): Trailer => {
      return res.data;
    });
};

export const fetchAccessCodeByTrailerId = (
  trailerId?: string
): Promise<TrailerAccessCode> => {
  if (!trailerId) {
    return Promise.reject('A trailerId is required');
  }

  return apiClient
    .get<TrailerAccessCode>(`trailers/${trailerId}/access_code/`)
    .then((res): TrailerAccessCode => {
      return res.data;
    });
};

export const unlockTrailerById = (
  trailerId?: string
): Promise<UnlockResponse> => {
  if (!trailerId) {
    return Promise.reject('A trailerId is required');
  }

  return apiClient
    .put<UnlockResponse>(`trailers/${trailerId}/unlock/`)
    .then((res): UnlockResponse => {
      return res.data;
    });
};

export const fetchTrailerStatusById = (
  trailerId?: string
): Promise<TrailerStatus> => {
  if (!trailerId) {
    return Promise.reject('A trailerId is required');
  }

  return apiClient
    .get(`trailers/${trailerId}/online/`)
    .then((res: AxiosResponse<TrailerStatus>) => res.data);
};

export const addTrailer = (trailerData: Trailer): Promise<Trailer> => {
  if (
    !trailerData ||
    !trailerData.license_plate ||
    !trailerData.license_state
  ) {
    return Promise.reject('Incomplete trailer data');
  }

  return apiClient
    .post<Trailer>('trailers/', trailerData)
    .then((res): Trailer => {
      return res.data;
    });
};

export const editTrailer = (trailerData: Trailer): Promise<Trailer> => {
  if (
    !trailerData ||
    !trailerData.trailer_id ||
    !trailerData.license_plate ||
    !trailerData.license_state
  ) {
    return Promise.reject('Incomplete trailer data');
  }

  return apiClient
    .put<Trailer>(`trailers/${trailerData.trailer_id}/`, trailerData)
    .then((res): Trailer => {
      return res.data;
    });
};

export const deleteTrailer = (trailerId?: string): Promise<void> => {
  if (!trailerId) {
    return Promise.reject('trailerId is required');
  }

  return apiClient.delete(`trailers/${trailerId}/`);
};

export const refreshTrailerSecret = (trailerId?: string): Promise<void> => {
  if (!trailerId) {
    return Promise.reject('trailerId is required');
  }

  return apiClient.post(`trailers/${trailerId}/refresh_secret/`);
};

export const getLastTrailerPASStatus = (
  trailerId?: string
): Promise<LastTrailerPASStatus> => {
  if (!trailerId) {
    return Promise.reject('trailerId is required');
  }

  return apiClient
    .get<LastTrailerPASStatusResponse>(`trailers/${trailerId}/last_status/`)
    .then((res) => {
      return {
        ...res.data,
        device_time: parseISO(res.data.device_time),
        received_time: parseISO(res.data.received_time),
      };
    });
};

export const refreshTrailerPASStatus = (trailerId?: string): Promise<void> => {
  if (!trailerId) {
    return Promise.reject('trailerId is required');
  }
  return apiClient.put(`trailers/${trailerId}/refresh_status/`);
};
