import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { IconButton, Paper, styled, Typography } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import BackArrowIcon from '@mui/icons-material/ArrowBack';
import LockIcon from '@mui/icons-material/Lock';
import SignalCellularNodataIcon from '@mui/icons-material/SignalCellularNodata';
import { useQuery } from 'react-query';
import { fetchActiveAssignmentForCurrentDriver } from '../../shared/api/assignments/assignments';
import useAlert from '../../shared/hooks/useAlert';
import { WithLoading } from '../../components/LoadingSpinner/LoadingSpinner';
import { ViewAccessCodeButton } from '../../components/ViewAccessCodeButton/ViewAccessCodeButton';
import { UnlockTrailerButton } from '../../components/UnlockTrailerButton/UnlockTrailerButton';
import { UnlockResponse } from '../../shared/types';
import { format, isSameDay } from 'date-fns';
import useAuth from '../../shared/hooks/useAuth';
import { TrailerStatusIndicator } from '../../components/TrailerStatusIndicator/TrailerStatusIndicator';
import { useTrailerStatus } from '../../shared/hooks/useTrailerStatus';
import { useTrailerPASStatus } from '../../shared/hooks/useTrailerPASStatus';

const TrailerLockStatus = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  flexWrap: 'wrap',
  fontSize: '90px',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(3),
}));

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',
  marginTop: 'auto',
  marginBottom: 0,
}));

const DriverAssignmentPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  maxWidth: '400px',
  minHeight: '600px',
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  alignItems: 'center',
}));

const UnlockInfoBanner = styled('div')(({ theme, hidden }) => {
  return {
    height: '40px',
    width: '100%',
    alignItems: 'center',
    gap: theme.spacing(2),
    display: hidden ? 'none' : 'flex',
    marginTop: 'auto',
    marginBottom: theme.spacing(2),
  };
});

const StatusBar = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'justify-around',
  minHeight: '36px',
  alignItems: 'center',
}));

const AssignmentDetailPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#192028',
  padding: theme.spacing(3, 2),
  width: '100%',
  marginTop: theme.spacing(4),
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'repeat(3, 1fr)',
  gridColumnGap: theme.spacing(1.5),
  gridRowGap: theme.spacing(1.5),
  alignItems: 'center',
}));

const TrailerAvailabilityContainer = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '12px',
  color: 'text.primary',
  margin: theme.spacing(3, 0),
}));

export const DriverAssignment: FC = () => {
  const { claims } = useAuth();
  const driverId = claims?.userId ?? '';

  const {
    data: assignmentData,
    isLoading: isAssignmentLoading,
    error: assignmentError,
  } = useQuery(
    ['assignment', driverId],
    () => fetchActiveAssignmentForCurrentDriver(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      cacheTime: 0,
      refetchInterval: 1000 * 60,
    }
  );

  const { trailerStatusData } = useTrailerStatus(assignmentData?.trailerId);
  const { doorStatus } = useTrailerPASStatus(
    assignmentData?.trailerId,
    trailerStatusData?.online
  );

  const { addAlert } = useAlert();

  useEffect(() => {
    if (assignmentError) {
      addAlert('Unable to fetch assignment', 'error');
    }
  }, [addAlert, assignmentError]);

  if (!claims || assignmentError) {
    return <DriverAssignmentPaper>Something went wrong.</DriverAssignmentPaper>;
  }

  if (!assignmentData) {
    return (
      <DriverAssignmentPaper>
        <WithLoading isLoading={isAssignmentLoading}>
          No trailer assigned.
        </WithLoading>
      </DriverAssignmentPaper>
    );
  }
  const assignment = assignmentData;
  const isTrailerOnline = trailerStatusData?.online;

  return (
    <DriverAssignmentPaper>
      <WithLoading isLoading={isAssignmentLoading} sx={{ maxHeight: '100%' }}>
        <TrailerUnlock
          trailerId={assignment.trailerId}
          isOnline={isTrailerOnline}
          enclosureStatus={doorStatus}
          loadId={assignment.loadId ?? ''}
          licensePlate={assignment.trailerPlate ?? ''}
          licensePlateState={assignment.trailerPlateState ?? ''}
          startTime={assignment.startTime}
          endTime={assignment.endTime}
        />
      </WithLoading>
    </DriverAssignmentPaper>
  );
};

interface TrailerUnlockProps {
  trailerId: string;
  isOnline?: boolean;
  enclosureStatus: string;
  loadId: string;
  licensePlate: string;
  licensePlateState: string;
  startTime: Date;
  endTime: Date;
}

const TrailerUnlock: FC<TrailerUnlockProps> = ({
  trailerId,
  isOnline,
  enclosureStatus,
  loadId,
  licensePlate,
  licensePlateState,
  startTime,
  endTime,
}) => {
  const [unlockingState, setUnlockingState] = useState<
    'initial' | 'unlocking' | 'unlocked'
  >('initial');
  const [accessCodeRevealed, setAccessCodeRevealed] = useState(false);
  const [unlockAC, setUnlockAC] = useState<string | undefined>();

  const cleanup = useCallback(() => {
    setUnlockingState('initial');
    setAccessCodeRevealed(false);
    setUnlockAC(undefined);
  }, []);

  return (
    <Fragment>
      <StatusBar>
        {unlockingState === 'unlocked' ? (
          <IconButton aria-label="back" onClick={cleanup}>
            <BackArrowIcon fontSize="small" sx={{ color: '#8B92A5' }} />
          </IconButton>
        ) : null}
        <TrailerStatusIndicator
          sx={{ justifyContent: 'flex-end' }}
          isOnline={isOnline}
        />
      </StatusBar>
      <TrailerLockStatus>
        <WithLoading
          isLoading={unlockingState === 'unlocking'}
          spinnerSize={40}
          containerSize={90}
        >
          {unlockingState === 'initial' ? (
            <LockIcon fontSize="inherit" sx={{ color: '#8b92a5' }} />
          ) : (
            <LockOpenIcon fontSize="inherit" sx={{ color: '#8b92a5' }} />
          )}
        </WithLoading>
      </TrailerLockStatus>
      <Typography variant="subtitle2">{enclosureStatus}</Typography>
      <AssignmentDetailPaper>
        <Typography variant="subtitle2">Load ID</Typography>
        <Typography variant="subtitle1" color="text.primary" textAlign="end">
          {loadId}
        </Typography>
        <Typography variant="subtitle2">License Plate</Typography>
        <Typography variant="subtitle1" color="text.primary" textAlign="end">
          {licensePlate}
        </Typography>
        <Typography variant="subtitle2">State</Typography>
        <Typography variant="subtitle1" color="text.primary" textAlign="end">
          {licensePlateState}
        </Typography>
      </AssignmentDetailPaper>
      <TrailerAvailabilityLabel startTime={startTime} endTime={endTime} />
      <UnlockInfoBanner
        hidden={unlockingState !== 'unlocked' && !accessCodeRevealed}
      >
        <SignalCellularNodataIcon />
        <Typography fontSize={12}>
          Remember to <b>write down the PIN</b> in case of signal loss or other
          emergency
        </Typography>
      </UnlockInfoBanner>

      <Actions>
        {unlockingState !== 'unlocked' && isOnline && (
          <UnlockTrailerButton
            trailerId={trailerId}
            disabled={!isOnline}
            onFetching={(): void => setUnlockingState('unlocking')}
            onSuccess={(r: UnlockResponse): void => {
              setUnlockingState('unlocked');
              setUnlockAC(r.access_code);
            }}
            onError={(): void => {
              setUnlockingState('initial');
            }}
          />
        )}
        <ViewAccessCodeButton
          trailerId={trailerId}
          accessCode={unlockAC}
          onSuccess={(): void => setAccessCodeRevealed(true)}
          onHide={(): void => setAccessCodeRevealed(false)}
        />
      </Actions>
    </Fragment>
  );
};

interface TrailerAvailabilityLabelProps {
  startTime: Date;
  endTime: Date;
}

const TrailerAvailabilityLabel: FC<TrailerAvailabilityLabelProps> = ({
  startTime,
  endTime,
}) => {
  return (
    <TrailerAvailabilityContainer>
      Available: {format(startTime, "MM/dd/yyyy HH:mm a' - ")}
      {isSameDay(startTime, endTime)
        ? format(endTime, 'HH:mm a')
        : format(endTime, 'MM/dd/yyyy HH:mm a')}
    </TrailerAvailabilityContainer>
  );
};
