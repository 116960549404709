import { Driver, Paginated } from '../../types';
import { AxiosResponse } from 'axios';
import { apiClient } from '../http-common';

export const fetchDriverById = (driverId?: string): Promise<Driver> => {
  if (!driverId) {
    return Promise.reject('A trailerId is required');
  }

  return apiClient
    .get<Driver>(`drivers/${driverId}/`)
    .then((res: AxiosResponse<Driver>): Driver => {
      return res.data;
    });
};

export const searchDrivers = (search: string): Promise<Driver[]> => {
  return apiClient
    .get<Paginated<Driver>>('drivers/', { params: { search, page: 1 } })
    .then((res) => {
      return res.data.results;
    });
};

export const fetchPaginatedDrivers = (
  page = 1,
  search?: string
): Promise<Paginated<Driver>> => {
  return apiClient
    .get<Paginated<Driver>>('drivers/', { params: { page, search } })
    .then((res) => {
      return res.data;
    });
};

export const fetchAllDrivers = (): Promise<Driver[]> => {
  return apiClient
    .get<Driver[]>('drivers/', { params: { all: '' } })
    .then((res) => {
      return res.data;
    });
};

export const addDriver = (driver: Driver): Promise<Driver> => {
  return apiClient.post<Driver>('drivers/', driver).then((res) => {
    return res.data;
  });
};

export const editDriver = (driver: Driver): Promise<Driver> => {
  return apiClient
    .put<Driver>(`drivers/${driver.driver_id}/`, driver)
    .then((res) => {
      return res.data;
    });
};

export const deleteDriver = (driverId?: string): Promise<void> => {
  if (!driverId) {
    return Promise.reject('A driverId is required');
  }

  return apiClient.delete(`drivers/${driverId}/`);
};
