import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import useAlert from '../../shared/hooks/useAlert';
import { useMutation } from 'react-query';
import { unlockTrailerById } from '../../shared/api/trailers';
import { UnlockResponse } from '../../shared/types';
import { AxiosError } from 'axios';

export interface UnlockTrailerButtonProps {
  trailerId: string;
  onSuccess?: (res: UnlockResponse) => void;
  onFetching?: () => void;
  onError?: () => void;
  autoreset?: boolean;
  disabled?: boolean;
}

/**
 * Button that encapsulates logic for unlocking a trailer.
 *
 * @param trailerId The id of the trailer to unlock.
 * @param onSuccess Callback that is called once unlocking succeeds with `UnlockResponse` as parameter.
 * @param onFetching Callback that is called when an unlock call begins.
 * @param onError Callback that is called when an error happens while trying to unlock.
 * @param autoreset Whether the button should return to a initial state after 30s
 * @param disabled If the button should be disabled.
 * @constructor
 */
export const UnlockTrailerButton: FC<UnlockTrailerButtonProps> = ({
  trailerId,
  onSuccess,
  onFetching,
  onError,
  autoreset = false,
  disabled = false,
}) => {
  const [unlockingState, setUnlockingState] = useState<
    'initial' | 'unlocking' | 'unlocked'
  >('initial');
  const { addAlert } = useAlert();

  const unlockMutation = useMutation(() => unlockTrailerById(trailerId), {
    retry: false,
    onError: (err: AxiosError<{ message: string }>) => {
      if (err?.response?.data.message === 'Insufficient power to unlock') {
        addAlert('Unable to unlock trailer', 'warning', {
          description: (
            <Typography variant="caption">
              Insufficient power to unlock, connect enclosure to external power
              and try again.
            </Typography>
          ),
        });
      } else {
        addAlert('Unable to unlock trailer.', 'error');
      }
      setUnlockingState('initial');
      onError && onError();
    },
    onSuccess: (res: UnlockResponse) => {
      addAlert(
        `Successfully requested trailer unlock with code ${res?.access_code}.`,
        'success',
        {
          description: (
            <Typography variant="caption">
              Press the Enter button to open the door.
            </Typography>
          ),
        }
      );
      setUnlockingState('unlocked');
      onSuccess && onSuccess(res);
    },
  });

  useEffect(() => {
    if (unlockingState === 'unlocking') {
      onFetching && onFetching();
    }
  }, [onFetching, unlockingState]);

  useEffect(() => {
    let timeout: number;
    if (autoreset && unlockingState === 'unlocked') {
      timeout = window.setTimeout(() => {
        setUnlockingState('initial');
      }, 30 * 1000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [autoreset, unlockingState]);

  const doUnlock = useCallback(() => {
    setUnlockingState('unlocking');
    unlockMutation.mutate();
  }, [unlockMutation]);

  const getUnlockingStateLabel = (label: string): string => {
    switch (label) {
      case 'initial':
        return 'Unlock Trailer';
      case 'unlocking':
        return 'Unlocking...';
      case 'unlocked':
        return 'Unlocked';
      default:
        return '';
    }
  };

  return (
    <Button
      variant="contained"
      fullWidth
      onClick={doUnlock}
      disabled={unlockingState !== 'initial' || disabled}
      sx={{
        height: '40px',
        '&.Mui-disabled': {
          background: '#4AA283',
          color: 'text.primary',
        },
      }}
    >
      {getUnlockingStateLabel(unlockingState)}
    </Button>
  );
};
