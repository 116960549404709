import { useQuery } from 'react-query';
import {
  getLastTrailerPASStatus,
  refreshTrailerPASStatus,
} from '../api/trailers';
import { differenceInMinutes, formatDistance } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';

export const useTrailerPASStatus = (
  trailerId?: string,
  isTrailerOnline?: boolean
) => {
  const [refetchForMissingRequested, setRefetchForMissingRequested] =
    useState(false);
  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['trailerpas_status', trailerId],
    () => getLastTrailerPASStatus(trailerId),
    {
      enabled: !!trailerId,
      refetchInterval: 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: 1,
      onError: (err: AxiosError) => {
        if (err.response?.status === 404 && refetchForMissingRequested) {
          setRefetchForMissingRequested(true);
          doRefresh();
        }
      },
    }
  );

  const doRefresh = useCallback(() => {
    refreshTrailerPASStatus(trailerId).catch(() => {
      /*noop*/
    });
    refetch().catch(() => {
      /*noop*/
    });
    setRefetchForMissingRequested((_) => false);
  }, [refetch, trailerId]);

  useEffect(() => {
    if (
      isTrailerOnline &&
      data?.device_time &&
      Math.abs(differenceInMinutes(data?.device_time, new Date())) > 60
    ) {
      doRefresh();
    }
  }, [data?.device_time, doRefresh, isTrailerOnline]);

  const [lastUpdateLabel, setLastUpdateLabel] = useState(
    getDistanceFromLastUpdate(data?.device_time)
  );

  // Create an interval that updates `lastUpdateLabel` every 10s
  useEffect(() => {
    setLastUpdateLabel(getDistanceFromLastUpdate(data?.device_time));

    const intervalId: number = window.setInterval(() => {
      setLastUpdateLabel(getDistanceFromLastUpdate(data?.device_time));
    }, 10 * 1000);
    return (): void => {
      clearInterval(intervalId);
    };
  }, [data?.device_time]);

  return {
    isLoading,
    doorStatus: isLoading
      ? 'Querying door status'
      : getDoorStatus(data?.door_status),
    powerStatus: isLoading
      ? 'Querying power status'
      : getPowerStatus(data?.battery_powered),
    isEnclosureOpen: isEnclosureOpen(data?.door_status),
    isOnBatteryPower: isOnBatteryPower(data?.battery_powered),
    lastUpdateLabel: lastUpdateLabel
      ? `Updated ${lastUpdateLabel}`
      : 'Never updated',
    refresh: doRefresh,
    isRefetching,
  };
};

const getDoorStatus = (doorStatus?: 'open' | 'closed' | string): string => {
  switch (doorStatus) {
    case 'open':
      return 'Enclosure open';
    case 'closed':
      return 'Enclosure closed';
    default:
      return 'Door status unknown';
  }
};

const isEnclosureOpen = (doorStatus?: 'open' | string): boolean => {
  return doorStatus === 'open';
};

const getPowerStatus = (isBattery?: 'true' | 'false' | string): string => {
  switch (isBattery) {
    case 'true':
      return 'Battery powered';
    case 'false':
      return 'Externally powered';
    default:
      return 'Power status unknown';
  }
};

const isOnBatteryPower = (isBattery?: 'true' | string): boolean => {
  return isBattery === 'true';
};

const getDistanceFromLastUpdate = (lastUpdateAt?: Date): string | undefined => {
  if (!lastUpdateAt) return undefined;
  return formatDistance(lastUpdateAt, new Date(), { addSuffix: true });
};
