import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  BaseTextFieldProps,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import { useQuery } from 'react-query';
import { useDebouncedState } from '../../shared/hooks/useDebouncedState';

export interface AutocompleteSearchFieldProps<T> {
  fieldLabel: string;
  getLabel: (value: T) => string;
  searchFn: (query: string) => Promise<T[]>;
  queryKey: string;
  value: T | null;
  onChange: (value: T | null) => void;
  error?: BaseTextFieldProps['error'];
  helperText?: BaseTextFieldProps['helperText'];
  disabled?: boolean;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
}

export const AutocompleteSearchField = <T,>({
  value,
  onChange,
  getLabel,
  searchFn,
  queryKey,
  fieldLabel,
  error,
  helperText,
  disabled,
  isOptionEqualToValue,
  sx,
  fullWidth = true,
}: AutocompleteSearchFieldProps<T>): JSX.Element => {
  const [inputValue, setInputValue] = useState('');
  const debouncedSearchValue = useDebouncedState(inputValue);
  const [options, setOptions] = useState<T[]>(value ? [value] : []);

  const { isLoading, data } = useQuery([queryKey, debouncedSearchValue], () =>
    searchFn(debouncedSearchValue)
  );

  useEffect(() => {
    let newOptions: T[] = [];

    if (value && newOptions) {
      newOptions = [value];
    }
    if (data) {
      newOptions = [...newOptions, ...data];
    }
    setOptions(newOptions);
  }, [data, value]);

  return (
    <Autocomplete
      sx={sx}
      fullWidth={fullWidth}
      filterOptions={(x: T[]): T[] => x}
      autoComplete
      getOptionLabel={(option): string =>
        typeof option === 'string' ? option : getLabel(option)
      }
      loading={isLoading}
      options={options}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={(e, data): void => onChange(data)}
      filterSelectedOptions
      value={value}
      disabled={disabled}
      autoHighlight
      onClose={(): void => {
        setInputValue('');
      }}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          label={fieldLabel}
          size="medium"
          value={inputValue}
          onChange={(e): void => setInputValue(e.target.value)}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};
