export const statusLabel = (label: boolean): string => {
  if (label) return 'Active';
  else return 'Inactive';
};
export const getRoleLabel = (role?: string): string => {
  switch (role) {
    case 'OWNER':
      return 'Owner';
    case 'MANAGER':
      return 'Manager';
    case 'DISPATCHER':
      return 'Dispatcher';
    default:
      return '';
  }
};
