import { apiClient } from '../http-common';
import type { APIFunction, GenericErrorResponse } from '../http-common';
import type { RefreshTokensResponse, UserTokens } from '../../types';

export const refreshAccessToken: APIFunction<string, UserTokens> = async (
  refreshToken
) => {
  try {
    const response = await apiClient.post<RefreshTokensResponse>(
      'auth/token/refresh/',
      {
        refresh: refreshToken,
      }
    );

    return { accessToken: response.data.access, refreshToken: refreshToken };
  } catch (err) {
    const axiosError = err as GenericErrorResponse;

    if (axiosError.response?.status === 401) {
      throw axiosError;
    }

    const { data } = axiosError.response ?? {};

    throw data ?? [];
  }
};
