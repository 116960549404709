import React, { FC, Fragment, ReactNode, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { Administrator } from '../../shared/types';
import { fetchAdministratorById } from '../../shared/api/administrators/administrators';
import useAlert from '../../shared/hooks/useAlert';
import DetailLayout from '../../layouts/DetailLayout/DetailLayout';
import { getRoleLabel, statusLabel } from '../../shared/utils/DetailPagesUtils';
import { DetailListCardView } from '../../components/DetailListCardView/DetailListCardView';
import { WithLoading } from '../../components/LoadingSpinner/LoadingSpinner';
import useModal from '../../shared/hooks/useModal';
import { AdministratorModal } from '../../components/AdministratorModal/AdministratorModal';
import { ResetPasswordButton } from '../../components/ResetPasswordButton/ResetPasswordButton';

type AdministratorParams = {
  adminId: string;
};

export const AdministratorDetail: FC = () => {
  const { adminId } = useParams<AdministratorParams>();
  const adminModal = useModal();

  const queryClient = useQueryClient();
  const { data, isLoading, error } = useQuery(
    ['administrators', adminId],
    () => fetchAdministratorById(adminId),
    {
      initialData: () => {
        const adminsQueryData: Administrator[] | undefined =
          queryClient.getQueryData(['administrators']);
        return adminsQueryData?.find((d) => d.id === adminId);
      },
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(['admins'])?.dataUpdatedAt,
    }
  );

  const { addAlert } = useAlert();
  useEffect(() => {
    const isFetchedDataEmpty = !isLoading && !data;

    if (error || isFetchedDataEmpty) {
      addAlert('Unable to fetch Administrator detail', 'error');
    }
  }, [addAlert, error, data, isLoading]);

  return (
    <DetailLayout
      title="Administrator Info"
      actionLabel="Edit information"
      action={adminModal.open}
    >
      <WithLoading isLoading={isLoading && !data}>
        <AdministratorDetailCard admin={data} />
      </WithLoading>
      {adminModal.isOpen ? (
        <AdministratorModal
          isOpen={adminModal.isOpen}
          close={adminModal.close}
          adminData={data}
        />
      ) : null}
    </DetailLayout>
  );
};

const AdministratorDetailCard: FC<{ admin?: Administrator }> = ({ admin }) => {
  if (!admin) return <Fragment />;
  const labelsToValues: [string, ReactNode][] = [
    ['Admin Name', `${admin.first_name} ${admin.last_name}`],
    ['Email', admin.email],
    ['Role', getRoleLabel(admin.role)],
    ['Status', statusLabel(admin.is_active)],
    [
      'Password',
      <ResetPasswordButton
        key={`password-reset-${admin.email}`}
        email={admin.email}
      />,
    ],
  ];

  return <DetailListCardView labelsToValues={labelsToValues} />;
};
