import { apiClient } from '../http-common';
import { CredentialResponse } from '@react-oauth/google';
import { LoginResponse, UserTokensWithUserData } from '../../types';
import { AxiosError } from 'axios';

export const loginWithGoogle = async (
  credentials: CredentialResponse,
  onError: (desc: string) => void
): Promise<UserTokensWithUserData> => {
  try {
    const response = await apiClient.post<LoginResponse>(
      'auth/google/',
      credentials
    );
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
      user: response.data.user,
    };
  } catch (err) {
    const axiosError = err as AxiosError<{
      non_field_errors: string[] | undefined;
    }>;
    if (axiosError.response?.data.non_field_errors) {
      const errors = axiosError.response.data.non_field_errors;
      if (errors.length > 0) {
        onError(errors[0]);
      }
    } else {
      onError('Unknown error');
    }
    throw err;
  }
};
