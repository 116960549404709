import React, { useCallback } from 'react';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Alert, Box, Button, Link, TextField, Typography } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { UserCredentials } from '../../shared/types';
import useAuth from '../../shared/hooks/useAuth';
import { GoogleLogin } from '@react-oauth/google';
import { loginWithGoogle } from '../../shared/api/users/loginWithGoogle';

function Signin(): JSX.Element {
  const { login, loginWithProvider, isLoggedIn } = useAuth();

  const signInSchema = yup.object().shape({
    username: yup.string().required('Username is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(20, 'Password must be at most 20 characters'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
      apiError: undefined,
    },
    resolver: yupResolver(signInSchema),
  });

  const submitHandler = useCallback(
    async (formData: UserCredentials) => {
      try {
        await login(formData);
      } catch (e) {
        if (typeof e === 'object' && e !== null && 'non_field_errors' in e) {
          setError('apiError', { message: 'Invalid login credentials' });
        } else {
          setError('apiError', { message: 'An error has occurred' });
        }
      }
    },
    [login, setError]
  );

  if (isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Box sx={{ mb: errors.apiError ? 2 : 4, textAlign: 'center' }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Sign in to TrailerPAS
        </Typography>
        <Typography variant="body2" sx={{ color: '#8B92A5' }}>
          Enter your credentials to continue
        </Typography>
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit((data) => submitHandler(data))}
        sx={{ width: '400px', textAlign: 'center', mx: 'auto' }}
      >
        {errors.apiError ? (
          <Box sx={{ mb: 2 }}>
            <Alert severity="error" variant="filled">
              {errors.apiError.message}
            </Alert>
          </Box>
        ) : null}
        <Box sx={{ mb: 3 }}>
          <TextField
            {...register('username')}
            id="username"
            label="Username"
            type="text"
            autoComplete="off"
            fullWidth
            error={!!errors.username}
            helperText={errors.username?.message}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            {...register('password')}
            id="password"
            label="Password"
            type="password"
            autoComplete="off"
            fullWidth
            error={!!errors.password}
            helperText={errors.password?.message}
          />
        </Box>

        <Typography variant="body2" sx={{ mb: 4 }}>
          Forgot password?{' '}
          <Link underline="none" component={RouterLink} to="../recover">
            Recover Here
          </Link>
        </Typography>
        <Button
          variant="contained"
          sx={{ mb: 2, width: '400px' }}
          type="submit"
          fullWidth
        >
          Sign In
        </Button>
        <GoogleLogin
          text="signin"
          size="large"
          width={400}
          onSuccess={(c) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            loginWithProvider(() =>
              loginWithGoogle(c, (e) => setError('apiError', { message: e }))
            ).catch(/* noop */);
          }}
          onError={() => {
            setError('apiError', {
              message: 'An error occurred while using Google Sign-In',
            });
          }}
        />
      </Box>
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Link href="#" underline="none">
          Go back to{' '}
          <Box component="span" sx={{ textDecoration: 'underline' }}>
            trailerpas.com
          </Box>
        </Link>
      </Box>
    </>
  );
}

export default Signin;
