import { UserType } from '../types';

export function hasMatchingRole(
  requiredRole: UserType,
  userRoles?: UserType[]
): boolean;
export function hasMatchingRole(
  requiredRoles: UserType[],
  userRoles?: UserType[]
): boolean;
export function hasMatchingRole(
  requiredRoles: UserType | UserType[],
  userRoles?: UserType[]
): boolean {
  if (!userRoles) return false;
  if (typeof requiredRoles === 'string') {
    return userRoles.includes(requiredRoles);
  } else {
    return !!requiredRoles.find((rr) => userRoles.includes(rr));
  }
}
