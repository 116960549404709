import React, { FC, useEffect, useState } from 'react';
import { ModalParams } from '../../shared/hooks/useModal';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Driver } from '../../shared/types';
import { useMutation, useQueryClient } from 'react-query';
import {
  addDriver,
  deleteDriver,
  editDriver,
} from '../../shared/api/drivers/drivers';
import useAlert from '../../shared/hooks/useAlert';
import { STATES } from '../../shared/constants';
import { useNavigate } from 'react-router-dom';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';

type TrailerModalProps = ModalParams & {
  driverData?: Driver;
};

const driverSchema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  email: yup.string().required('Email is required'),
  cdl_id: yup.string().required('CDL is required'),
  cdl_state: yup.string().required('CDL State is required'),
  phone_number: yup.string().required('Phone number is required'),
});

export const DriverModal: FC<TrailerModalProps> = ({
  isOpen,
  close,
  driverData,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<Driver>({
    defaultValues: {
      first_name: '' ?? driverData?.first_name,
      last_name: '' ?? driverData?.last_name,
      email: '' ?? driverData?.email,
      cdl_id: '' ?? driverData?.cdl_id,
      cdl_state: '' ?? driverData?.cdl_state,
      phone_number: '' ?? driverData?.phone_number,
      is_active: true,
    },
    resolver: yupResolver(driverSchema),
  });

  const [apiError, setApiError] = useState<string>();
  const [modalState, setModalState] = useState<'edit' | 'confirmation'>('edit');
  const isEditing = !!driverData;

  const closeCleanup = (): void => {
    reset();
    setApiError('');
    close();
  };

  useEffect(() => {
    reset(driverData);
  }, [reset, driverData]);

  const { addAlert } = useAlert();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const addMutation = useMutation((driver: Driver) => addDriver(driver), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('drivers');
      addAlert('Driver created successfully', 'success');
      closeCleanup();
    },
    onError: () => setApiError('An error has occurred'),
  });
  const editMutation = useMutation((driver: Driver) => editDriver(driver), {
    onSuccess: async () => {
      // driver_id is an integer here, but it's a string for the queryKey on
      // DriverDetail. Cast to string to match that one and avoid stale data
      // on DriverDetail.
      const queryKey = ['driver', driverData?.driver_id.toString()];
      await queryClient.invalidateQueries(queryKey);
      addAlert('Driver updated successfully', 'success');
      closeCleanup();
    },
    onError: () => {
      setApiError('An error has occurred');
      setModalState('edit');
    },
  });
  const deleteMutation = useMutation(
    () => deleteDriver(driverData?.driver_id),
    {
      onSuccess: async () => {
        addAlert('Successfully deleted Driver.', 'success', {
          dismissOnLocationChange: false,
          timeoutToDismiss: 5000,
        });
        closeCleanup();
        navigate('/drivers');
        await queryClient.invalidateQueries('drivers');
      },
      onError: () => {
        setApiError('An error has occurred');
        setModalState('edit');
      },
    }
  );

  const submitHandler: SubmitHandler<Driver> = (data): void => {
    if (data.driver_id) {
      editMutation.mutate(data);
    } else {
      addMutation.mutate(data);
    }
  };

  return modalState === 'edit' ? (
    <Dialog open={isOpen} onClose={() => null} maxWidth="sm" fullWidth>
      <form
        noValidate
        autoComplete="off"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitHandler)}
      >
        <DialogTitle>
          {isEditing ? 'Edit ' : 'Add New '}
          Driver
        </DialogTitle>
        <DialogContent>
          {apiError ? (
            <Box mb={3}>
              <Alert severity="error" variant="filled">
                {apiError}
              </Alert>
            </Box>
          ) : null}
          <Stack mt={2} spacing={4}>
            <TextField
              {...register('first_name')}
              id="first_name"
              label="First Name"
              type="text"
              autoComplete="off"
              fullWidth
              error={!!errors.first_name}
              helperText={errors.first_name?.message}
            />
            <TextField
              {...register('last_name')}
              id="last_name"
              label="Last Name"
              type="text"
              autoComplete="off"
              fullWidth
              error={!!errors.last_name}
              helperText={errors.last_name?.message}
            />
            <TextField
              {...register('cdl_id')}
              id="cdl_id"
              label="Commercial Driver's License ID"
              type="text"
              autoComplete="off"
              fullWidth
              error={!!errors.cdl_id}
              helperText={errors.cdl_id?.message}
            />
            <Controller
              name="cdl_state"
              control={control}
              render={({ field }): JSX.Element => (
                <TextField
                  {...field}
                  label="Commercial Driver's License State"
                  select
                  fullWidth
                  error={!!errors.cdl_state}
                  helperText={errors.cdl_state?.message}
                >
                  {STATES.map((stateAbbreviation) => (
                    <MenuItem key={stateAbbreviation} value={stateAbbreviation}>
                      {stateAbbreviation}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <TextField
              {...register('email')}
              id="email"
              label="Email"
              type="text"
              autoComplete="off"
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            <TextField
              {...register('phone_number')}
              id="phone_number"
              label="Phone Number"
              type="text"
              autoComplete="off"
              fullWidth
              error={!!errors.phone_number}
              helperText={errors.phone_number?.message}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ mx: 2, mb: 1 }}>
          {isEditing ? (
            <Button
              sx={{ ml: 0, mr: 'auto', color: 'error.main' }}
              onClick={(): void => setModalState('confirmation')}
            >
              Delete Driver
            </Button>
          ) : null}
          <Button onClick={closeCleanup}>Cancel</Button>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  ) : (
    <ConfirmationDialog
      title="Delete Driver?"
      description={
        <>
          Are you sure you want to delete this Driver? All information will be
          lost, including <b>Assignments</b>.
        </>
      }
      onConfirmation={(): void => deleteMutation.mutate()}
      confirmationLabel={<>Yes, Delete</>}
      onCancel={closeCleanup}
    />
  );
};
