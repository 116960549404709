import React, { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { usePaginatedQueryWithSearch } from '../../shared/hooks/usePaginatedQueryWithSearch';
import { fetchPaginatedAdministrators } from '../../shared/api/administrators/administrators';
import DetailLayout from '../../layouts/DetailLayout/DetailLayout';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Administrator } from '../../shared/types';
import { Box, Button, TextField } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useModal from '../../shared/hooks/useModal';
import { AdministratorModal } from '../../components/AdministratorModal/AdministratorModal';
import { getRoleLabel } from '../../shared/utils/DetailPagesUtils';
import useAuth from '../../shared/hooks/useAuth';

export const AdministratorList: FC = () => {
  const { activeCompany } = useAuth();
  const { isLoading, items, totalRowCount, setPage } =
    usePaginatedQueryWithSearch('administrators', fetchPaginatedAdministrators);

  const adminModal = useModal();

  if (isLoading) return <span>Loading...</span>;

  const tableColumns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: 'Name',
      flex: 1,
      valueGetter: (params): string => {
        const { first_name, last_name } = params.row as Administrator;
        return `${first_name} ${last_name}`;
      },
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <PeopleAltIcon fontSize="small" />
          {params.value}
        </Box>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 0.4,
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 0.3,
      valueGetter: (params: GridRenderCellParams<string>) =>
        getRoleLabel(params.value),
    },
    {
      field: 'id',
      headerName: '',
      renderCell: (params: GridRenderCellParams<string, Administrator>) => (
        <Button
          variant="outlined"
          component={RouterLink}
          to={`${params.value}`}
        >
          See Details
        </Button>
      ),
      sortable: false,
      minWidth: 150,
    },
  ];

  return (
    <DetailLayout
      title="Administrators"
      subtitle={`${activeCompany?.name} Admins`}
      actionLabel={
        <>
          {' '}
          Add New Admin <AddIcon fontSize="small" sx={{ ml: 1 }} />{' '}
        </>
      }
      action={adminModal.open}
      hasBackButton={false}
      sx={{ height: '70vh' }}
    >
      <Box sx={{ display: 'flex', alignItems: 'end', mb: 2 }}>
        <SearchIcon sx={{ mr: 1, my: 0.5, color: '#8B92A5' }} />
        <TextField
          label="Search"
          variant="standard"
          fullWidth
          sx={{ '& label': { typography: 'subtitle1' } }}
        />
      </Box>
      <DataGrid
        columns={tableColumns}
        pageSize={30}
        paginationMode="server"
        rowCount={totalRowCount}
        onPageChange={setPage}
        rowsPerPageOptions={[30]}
        rows={items}
        loading={isLoading}
        disableColumnSelector
        disableColumnMenu
        disableColumnFilter
        disableSelectionOnClick
      />
      {adminModal.isOpen ? (
        <AdministratorModal
          isOpen={adminModal.isOpen}
          close={adminModal.close}
        />
      ) : null}
    </DetailLayout>
  );
};
