import React, { FC } from 'react';
import { UserType } from '../../shared/types';
import useAuth from '../../shared/hooks/useAuth';
import { Navigate, Outlet } from 'react-router-dom';
import { hasMatchingRole } from '../../shared/utils/roleUtils';

type UserTypeWithWildCard = UserType[] | '*';

interface RequireAuthProps {
  userTypes: UserTypeWithWildCard;
}

const hasPermission = (
  allowedUserTypes: UserTypeWithWildCard,
  userRoles?: UserType[]
): boolean => {
  if (!userRoles) return false;

  return (
    allowedUserTypes === '*' || hasMatchingRole(allowedUserTypes, userRoles)
  );
};

export const RequireRole: FC<RequireAuthProps> = ({ userTypes }) => {
  const { activeCompany } = useAuth();
  return hasPermission(userTypes, activeCompany?.role) ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};
