import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Paginated } from '../types';
import { useDebouncedState } from './useDebouncedState';

export interface PaginatedQuery<T> {
  totalRowCount: number;
  setPage: (page: number) => void;
  isLoading: boolean;
  currentPage: number;
  items: T[];
}

export const usePaginatedQueryWithSearch = <T>(
  queryKey: string,
  queryFn: (page: number, search?: string) => Promise<Paginated<T>>,
  search?: string
): PaginatedQuery<T> => {
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [page, setPage] = useState(1);
  const debouncedSearch = useDebouncedState(search);
  useEffect(() => {
    if (search) {
      setPage(1);
    }
  }, [setPage, search]);

  const { isLoading, data } = useQuery(
    [queryKey, page, debouncedSearch],
    () => queryFn(page, debouncedSearch),
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (data) {
      setTotalRowCount((rc) => (data.count !== rc ? data.count : rc));
    }
  }, [data, setTotalRowCount]);

  const updatePage = useCallback(
    (n: number): void => {
      setPage(n + 1);
    },
    [setPage]
  );

  return {
    totalRowCount,
    setPage: updatePage,
    isLoading,
    items: data?.results ?? [],
    currentPage: page,
  };
};
