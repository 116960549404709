import React, { FC, useState } from 'react';
import DetailLayout from '../../layouts/DetailLayout/DetailLayout';
import { Box, Button, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Driver } from '../../shared/types';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { fetchPaginatedDrivers } from '../../shared/api/drivers/drivers';
import { usePaginatedQueryWithSearch } from '../../shared/hooks/usePaginatedQueryWithSearch';
import { Link as RouterLink } from 'react-router-dom';
import useModal from '../../shared/hooks/useModal';
import { DriverModal } from '../../components/DriverModal/DriverModal';
import useAuth from '../../shared/hooks/useAuth';
import { hasMatchingRole } from '../../shared/utils/roleUtils';

const DriverList: FC = () => {
  const { activeCompany } = useAuth();
  const userRoles = activeCompany?.role;
  const [search, setSearch] = useState('');

  const { isLoading, items, totalRowCount, setPage } =
    usePaginatedQueryWithSearch('drivers', fetchPaginatedDrivers, search);

  const driverModal = useModal();

  if (isLoading) return <span>Loading...</span>;

  const tableColumns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: 'Driver Name',
      flex: 1,
      valueGetter: (params): string => {
        const { first_name, last_name } = params.row as Driver;
        return `${first_name} ${last_name}`;
      },
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <PeopleAltIcon fontSize="small" />
          {params.value}
        </Box>
      ),
    },
    {
      field: 'cdl_id',
      headerName: "COMMERCIAL DRIVER'S LICENSE ID",
      flex: 0.3,
    },
    { field: 'cdl_state', headerName: 'CDL STATE', flex: 0.3 },
    {
      field: 'is_active',
      headerName: 'STATUS',
      flex: 0.3,
      renderCell: (params: GridRenderCellParams<boolean>): string => {
        return params.value ? 'Active' : 'Inactive';
      },
    },
    {
      field: 'driver_id',
      headerName: '',
      renderCell: (params: GridRenderCellParams<string, Driver>) => (
        <Button
          variant="outlined"
          component={RouterLink}
          to={`${params.value}`}
        >
          See Details
        </Button>
      ),
      sortable: false,
      minWidth: 150,
    },
  ];

  return (
    <DetailLayout
      title="Drivers"
      subtitle={`${activeCompany?.name} Drivers`}
      actionLabel={
        hasMatchingRole(['SUPERUSER', 'OWNER', 'MANAGER'], userRoles) ? (
          <>
            Add New Driver <AddIcon fontSize="small" sx={{ ml: 1 }} />
          </>
        ) : undefined
      }
      action={
        hasMatchingRole(['SUPERUSER', 'OWNER', 'MANAGER'], userRoles)
          ? driverModal.open
          : undefined
      }
      hasBackButton={false}
      sx={{ height: '70vh' }}
    >
      <Box sx={{ display: 'flex', alignItems: 'end', mb: 2 }}>
        <SearchIcon sx={{ mr: 1, my: 0.5, color: '#8B92A5' }} />
        <TextField
          label="Search"
          variant="standard"
          fullWidth
          sx={{ '& label': { typography: 'subtitle1' } }}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
      </Box>
      <DataGrid
        columns={tableColumns}
        pageSize={30}
        paginationMode="server"
        rowCount={totalRowCount}
        onPageChange={setPage}
        rowsPerPageOptions={[30]}
        rows={items}
        loading={isLoading}
        getRowId={(r: Driver): string => r.driver_id}
        disableColumnSelector
        disableColumnMenu
        disableColumnFilter
        disableSelectionOnClick
      />
      {driverModal.isOpen ? (
        <DriverModal isOpen={driverModal.isOpen} close={driverModal.close} />
      ) : null}
    </DetailLayout>
  );
};

export default DriverList;
