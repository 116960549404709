import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';

import {
  CompanyRole,
  SignInThunk,
  UserCredentials,
  UserData,
  UserTokenClaims,
  UserTokenClaimsResponse,
  UserTokens,
} from '../types';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { fetchJWTLogin } from '../api/users/fetchJWTLogin';
import jwtDecode from 'jwt-decode';

export type UserAuthContext = {
  user?: UserTokens;
  claims?: UserTokenClaims;
  userData?: UserData;
  activeCompany?: CompanyRole;
  isLoggedIn: boolean;
  login: (data: UserCredentials) => Promise<void>;
  loginWithProvider: (fn: SignInThunk) => Promise<void>;
  logout: () => void;
  setActiveCompany: (c?: CompanyRole) => void;
};

type AuthProviderProps = {
  initialUser?: UserTokens;
  initialClaims?: UserTokenClaims;
  initialUserData?: UserData;
  initialActiveCompany?: CompanyRole;
};

const initialContext: UserAuthContext = {
  user: undefined,
  claims: undefined,
  userData: undefined,
  activeCompany: undefined,
  isLoggedIn: false,
  login: (): Promise<void> => Promise.resolve(),
  loginWithProvider: () => Promise.resolve(),
  logout: (): void => {
    /*noop*/
  },
  setActiveCompany: (): void => {
    /*noop*/
  },
};

const getClaimsFromToken = (token: string): UserTokenClaims => {
  const decoded: UserTokenClaimsResponse = jwtDecode(token);
  return {
    userId: decoded['user_id'],
  };
};

const AuthContext = createContext<UserAuthContext>(initialContext);

export const AuthProvider: FC<PropsWithChildren<AuthProviderProps>> = ({
  children,
  initialUser,
  initialClaims,
  initialUserData,
  initialActiveCompany,
}) => {
  const [user, setUser, restoreUserFromLS] = useLocalStorage<UserTokens>(
    'user',
    initialUser
  );
  const [userData, setUserData] = useLocalStorage<UserData>(
    'userData',
    initialUserData
  );
  const [activeCompany, setActiveCompany] = useLocalStorage(
    'activeCompany',
    initialActiveCompany
  );
  const [claims, setClaims] = useState(initialClaims);
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = (location?.state as { from?: Location }) ?? {};

  const loginWithSignInThunk = useCallback(
    async (thunk: SignInThunk) => {
      const tokens = await thunk();
      setClaims(getClaimsFromToken(tokens.accessToken));
      setUserData(tokens.user);
      setUser(tokens);
      navigate(from?.pathname ?? '/', { replace: true });
    },
    [from?.pathname, navigate, setUser, setUserData]
  );

  const login = useCallback(
    (credentials: UserCredentials) => {
      return loginWithSignInThunk(() => fetchJWTLogin(credentials));
    },
    [loginWithSignInThunk]
  );

  const logout = useCallback(() => {
    setUser(undefined);
    setClaims(undefined);
    setUserData(undefined);
    navigate('/', { replace: true });
  }, [navigate, setUser, setUserData]);

  // When using the methods defined in localStorage.ts an Event will be emitted
  // when localStorage is manipulated.
  // Such a case is meet when an axios interceptor does a token refresh. Since
  // interceptors don't have access to the inner state of this Provider,  listen
  // to the event here to re-read the value from localStorage to keep the value
  // in localStorage and the Provider in sync.
  useEffect(() => {
    window.addEventListener('localstorage', restoreUserFromLS);
    return () => {
      window.removeEventListener('localstorage', restoreUserFromLS);
    };
  }, [restoreUserFromLS]);
  const isLoggedIn = useMemo(
    () => !!(user && user.accessToken && userData),
    [user, userData]
  );

  useEffect(() => {
    if (!isLoggedIn) {
      setClaims(undefined);
      setUser(undefined);
      setUserData(undefined);
      setActiveCompany(undefined);
    }
  }, [isLoggedIn, setActiveCompany, setUser, setUserData]);

  useEffect(() => {
    if (user) {
      setClaims(getClaimsFromToken(user.accessToken));
    }
  }, [setClaims, user]);

  const value = useMemo(
    () => ({
      user,
      claims,
      userData,
      isLoggedIn,
      activeCompany,
      setActiveCompany,
      login,
      loginWithProvider: loginWithSignInThunk,
      logout,
    }),
    [
      user,
      claims,
      userData,
      isLoggedIn,
      activeCompany,
      setActiveCompany,
      login,
      loginWithSignInThunk,
      logout,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
