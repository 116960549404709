import React from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';

import logo from '../../assets/images/trailerpas-logo.png';

const StyledAuthContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  justifyContent: 'center',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(7),
    justifyContent: 'flex-start',
  },
}));

const StyledLogoContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(7),

  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(2),
  },
}));

const StyledFormContainer = styled(Paper)(({ theme }) => ({
  borderRadius: '12px',
  width: theme.spacing(62.5),
  padding: theme.spacing(5, 6),

  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    padding: theme.spacing(5, 3),
    width: '100%',
  },
}));

function AuthLayout(): JSX.Element {
  return (
    <StyledAuthContainer>
      <StyledLogoContainer>
        <img
          src={logo}
          className="App-logo"
          alt="Allied Risk - TrailerPAS Logo"
        />
      </StyledLogoContainer>
      <StyledFormContainer>
        <Outlet />
      </StyledFormContainer>
    </StyledAuthContainer>
  );
}

export default AuthLayout;
