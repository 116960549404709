import React, { FC, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { ModalParams } from '../../shared/hooks/useModal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Trailer } from '../../shared/types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { STATES } from '../../shared/constants';
import { useMutation, useQueryClient } from 'react-query';
import {
  addTrailer,
  deleteTrailer,
  editTrailer,
  refreshTrailerSecret,
} from '../../shared/api/trailers';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import useAlert from '../../shared/hooks/useAlert';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

const trailerSchema = yup.object().shape({
  license_plate: yup.string().required('License Plate is required'),
  license_state: yup.string().required('License State is required').length(2),
});

type TrailerModalParams = ModalParams & {
  trailerData?: Trailer;
};

const TrailerModal: FC<TrailerModalParams> = ({
  isOpen,
  close,
  trailerData,
}: TrailerModalParams) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    control,
  } = useForm<Trailer>({
    defaultValues: {
      license_plate: trailerData?.license_plate ?? '',
      license_state: trailerData?.license_state ?? '',
      device_id: trailerData?.device_id ?? '',
    },
    resolver: yupResolver(trailerSchema),
  });

  useEffect(() => {
    // Populates the form with existing trailer data
    reset(trailerData);
  }, [reset, trailerData]);

  const [apiError, setApiError] = useState<string>();
  const [modalState, setModalState] = useState<'edit' | 'confirmation'>('edit');
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const isEditing = !!trailerData;

  const queryClient = useQueryClient();
  const addMutation = useMutation(
    (newTrailer: Trailer) => addTrailer(newTrailer),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('trailers');
        closeWrapper();
      },
      onError: (err: AxiosError<Trailer>) => {
        if (err?.response?.data?.device_id) {
          setError('device_id', {
            type: 'server',
            message: err.response.data.device_id,
          });
        }
        setApiError('An error has occurred');
      },
    }
  );

  const editMutation = useMutation(
    (existingTrailer: Trailer) => editTrailer(existingTrailer),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'trailer',
          trailerData?.trailer_id,
        ]);
        closeWrapper();
      },
      onError: (err: AxiosError<Trailer>) => {
        if (err?.response?.data?.device_id) {
          setError('device_id', {
            type: 'server',
            message: err.response.data.device_id,
          });
        }
        setApiError('An error has occurred');
      },
    }
  );

  const deleteMutation = useMutation(
    () => deleteTrailer(trailerData?.trailer_id),
    {
      onSuccess: async () => {
        addAlert('Successfully deleted Trailer.', 'success', {
          dismissOnLocationChange: false,
          timeoutToDismiss: 5000,
        });
        closeWrapper();
        navigate('/trailers');
        await queryClient.invalidateQueries('trailers');
      },
      onError: () => {
        setApiError('An error has occurred');
        setModalState('edit');
      },
    }
  );

  const refreshSecretMutation = useMutation(
    () => refreshTrailerSecret(trailerData?.trailer_id),
    {
      onSuccess: () => {
        addAlert('Successfully refreshed Trailer secret', 'success');
      },
      onError: () => {
        addAlert('An error occurred while refreshing Trailer secret', 'error');
      },
    }
  );

  function closeWrapper(): void {
    reset();
    setApiError('');
    close();
  }

  const submitHandler: SubmitHandler<Trailer> = (data: Trailer): void => {
    if (data.trailer_id) {
      editMutation.mutate(data);
    } else {
      addMutation.mutate(data);
    }
  };

  return modalState === 'edit' ? (
    <Dialog open={isOpen} onClose={() => null} maxWidth="sm" fullWidth>
      <form
        noValidate
        autoComplete="off"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitHandler)}
      >
        <DialogTitle> {isEditing ? 'Edit' : 'Add New'} Trailer</DialogTitle>
        <DialogContent>
          {apiError ? (
            <Box mb={3}>
              <Alert severity="error" variant="filled">
                {apiError}
              </Alert>
            </Box>
          ) : null}
          <Stack mt={2} spacing={4}>
            <TextField
              {...register('license_plate')}
              id="license_plate"
              label="License Plate"
              type="text"
              autoComplete="off"
              fullWidth
              error={!!errors.license_plate}
              helperText={errors.license_plate?.message}
            />
            <Controller
              name="license_state"
              control={control}
              render={({ field }): JSX.Element => (
                <TextField
                  {...field}
                  label="License State"
                  select
                  fullWidth
                  error={!!errors.license_state}
                  helperText={errors.license_state?.message}
                >
                  {STATES.map((stateAbbreviation) => (
                    <MenuItem key={stateAbbreviation} value={stateAbbreviation}>
                      {stateAbbreviation}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <TextField
              {...register('device_id')}
              id="device_id"
              label="Device ID"
              type="text"
              autoComplete="off"
              fullWidth
              error={!!errors.device_id}
              helperText={errors.device_id?.message}
            />
            {isEditing ? (
              <Button
                variant="outlined"
                onClick={(): void => refreshSecretMutation.mutate()}
              >
                Refresh Trailer Secret
              </Button>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ mx: 2, mb: 1 }}>
          {trailerData ? (
            <Button
              sx={{ ml: 0, mr: 'auto', color: 'error.main' }}
              onClick={(): void => setModalState('confirmation')}
            >
              Delete Trailer
            </Button>
          ) : null}
          <Button onClick={closeWrapper}>Cancel</Button>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  ) : (
    <ConfirmationDialog
      title="Delete Trailer?"
      description="Are you sure you want to delete this trailer? All information will be lost."
      onConfirmation={(): void => deleteMutation.mutate()}
      confirmationLabel={<>Yes, Delete</>}
      onCancel={closeWrapper}
    />
  );
};

export default TrailerModal;
