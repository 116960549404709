import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const baseTheme = createTheme({
  palette: {
    primary: {
      main: '#1BBF86',
      dark: '#4AA283',
      contrastText: '#FAFAFA',
    },
    secondary: {
      main: '#2969FF',
      dark: '#204EBD',
    },
    error: {
      main: '#F43F5E',
      dark: '#B00020',
    },
    info: {
      main: '#70C476',
      dark: '#243F36',
    },
    background: {
      default: '#192028',
      paper: '#232C35',
    },
    text: {
      primary: '#FAFAFA',
      secondary: 'rgb(250,250,250, 0.7)',
      disabled: 'rgb(250,250,250, 0.5)',
    },
    action: {
      active: '#FAFAFA',
      hover: 'rgb(250,250,250, 0.08)',
      selected: 'rgb(250,250,250, 0.16)',
      disabled: 'rgb(250,250,250, 0.3)',
      disabledBackground: 'rgb(250,250,250, 0.12)',
    },
    divider: 'rgb(250,250,250, 0.12)',
  },
  typography: {
    fontFamily: [
      'Plus Jakarta Sans',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 14,
    body1: {
      color: '#FAFAFA',
    },
    body2: {
      color: '#E7E5E4',
    },
    h3: {
      fontWeight: 400,
      fontSize: '48px',
      lineHeight: '56px',
    },
    h4: {
      fontWeight: 400,
      fontSize: '34px',
      lineHeight: '36px',
    },
    h5: {
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '24px',
    },
    h6: {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '20px',
    },
    subtitle1: {
      color: '#8B92A5',
      fontSize: '14px',
    },
    subtitle2: {
      color: '#FFFFFF',
      fontSize: '10px',
      textTransform: 'uppercase',
      letterSpacing: '1.5px',
      lineHeight: '16px',
    },
  },
});

const theme = createTheme(baseTheme, {
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '64px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#4AA283',
            },
          },
        },
        notchedOutline: {
          borderColor: '#71717A',
        },
        input: {
          color: '#FAFAF9',
          colorScheme: 'dark',
          '&:-webkit-autofill': {
            boxShadow: '0 0 0 100px #232C35 inset',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#FAFAFA',
          textTransform: 'none',
        },
        outlined: {
          borderColor: '#8B92A5',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '.875rem',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          '& .MuiListItemIcon-root': {
            minWidth: '40px',
          },
          '&:hover': {
            backgroundColor: '#192028',
            '& .MuiSvgIcon-root': {
              color: '#1BBF86',
            },
            '& .MuiTypography-root': {
              color: '#1BBF86',
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: '8px',
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.palette.background.paper,
          borderTopRightRadius: '10px',
          borderTopLeftRadius: '10px',
          padding: baseTheme.spacing(1.5),
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '16px',
          padding: baseTheme.spacing(0.5, 4),
          minHeight: '40px',
          textTransform: 'capitalize',
          height: '40px',
          '&.Mui-selected': {
            color: '#fff',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '6px',
          },
          '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 0,
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#232C35',
            fontSize: '10px',
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            border: '1px',
            borderStyle: 'solid',
            borderColor: '#232C35',
            borderRadius: '8px',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: '#232C35',
          },
          '& .MuiDataGrid-footerContainer': {
            border: 'none',
            fontSize: '10px',
          },
        },
      },
    },
  },
});

export default theme;
