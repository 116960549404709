import React, { FC } from 'react';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AddIcon from '@mui/icons-material/Add';
import {
  deleteYardJockey,
  fetchPaginatedYardJockeys,
} from '../../shared/api/yardjockeys';
import { usePaginatedQueryWithSearch } from '../../shared/hooks/usePaginatedQueryWithSearch';
import useAuth from '../../shared/hooks/useAuth';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { YardJockey } from '../../shared/types';
import { Box, Button } from '@mui/material';
import DetailLayout from '../../layouts/DetailLayout/DetailLayout';
import { hasMatchingRole } from '../../shared/utils/roleUtils';
import useModal, { ModalParams } from '../../shared/hooks/useModal';
import { YardJockeyModal } from '../../components/YardJockeyModal/YardJockeyModal';
import { ConfirmationDialog } from '../../components/ConfirmationDialog/ConfirmationDialog';
import useAlert from '../../shared/hooks/useAlert';
import { useMutation, useQueryClient } from 'react-query';

export const YardJockeyList: FC = () => {
  const { activeCompany } = useAuth();
  const userRoles = activeCompany?.role;

  const { isLoading, items, totalRowCount, setPage } =
    usePaginatedQueryWithSearch('jockeys', fetchPaginatedYardJockeys);

  const jockeyModal = useModal();
  // TODO: Should be able to delete this once we get the Edit modal for Jockeys.
  const deleteJockeyConfirmationModal = useModal<DeleteYardJockeyProps>();

  if (isLoading) return <span>Loading...</span>;

  const tableColumns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: 'Name',
      flex: 1,
      valueGetter: (params): string => {
        const { first_name, last_name } = params.row as YardJockey;
        return `${first_name} ${last_name}`;
      },
      renderCell: (params: GridRenderCellParams<string>) => (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <PeopleAltIcon fontSize="small" />
          {params.value}
        </Box>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'user_id',
      headerName: '',
      renderCell: (params: GridRenderCellParams<string, YardJockey>) => (
        <Button
          variant="outlined"
          color="error"
          sx={{ color: 'error.main' }}
          onClick={(): void =>
            deleteJockeyConfirmationModal.openWithProps({
              user_id: params.value,
            })
          }
        >
          Delete Jockey
        </Button>
      ),
      sortable: false,
      minWidth: 150,
    },
  ];

  return (
    <DetailLayout
      title="Yard Jockeys"
      subtitle={`${activeCompany?.name} Yard Jockeys`}
      actionLabel={
        hasMatchingRole(['SUPERUSER', 'OWNER', 'MANAGER'], userRoles) ? (
          <>
            Add New Jockey <AddIcon fontSize="small" sx={{ ml: 1 }} />
          </>
        ) : undefined
      }
      action={
        hasMatchingRole(['SUPERUSER', 'OWNER', 'MANAGER'], userRoles)
          ? jockeyModal.open
          : undefined
      }
      hasBackButton={false}
      sx={{ height: '70vh' }}
    >
      <DataGrid
        columns={tableColumns}
        pageSize={30}
        paginationMode="server"
        rowCount={totalRowCount}
        onPageChange={setPage}
        rowsPerPageOptions={[30]}
        rows={items}
        loading={isLoading}
        getRowId={(r: YardJockey): string => r.user_id.toString()}
        disableColumnSelector
        disableColumnMenu
        disableColumnFilter
        disableSelectionOnClick
      />
      {jockeyModal.isOpen ? (
        <YardJockeyModal
          isOpen={jockeyModal.isOpen}
          close={jockeyModal.close}
        />
      ) : null}
      {deleteJockeyConfirmationModal.isOpen ? (
        <DeleteYardJockey
          {...deleteJockeyConfirmationModal}
          {...deleteJockeyConfirmationModal.dynamicProps}
        />
      ) : null}
    </DetailLayout>
  );
};

type DeleteYardJockeyProps = ModalParams & {
  user_id?: string;
};
const DeleteYardJockey: FC<DeleteYardJockeyProps> = ({
  user_id,
  isOpen,
  close,
}) => {
  const { addAlert } = useAlert();

  const queryClient = useQueryClient();
  const deleteMutation = useMutation(() => deleteYardJockey(user_id), {
    onSuccess: async () => {
      addAlert('Successfully deleted Yard Jockey.', 'success', {
        dismissOnLocationChange: false,
        timeoutToDismiss: 5000,
      });
      close();
      await queryClient.invalidateQueries('jockeys');
    },
    onError: () => {
      addAlert('An error occurred deleting Yard Jockey.', 'error');
      close();
    },
  });

  return isOpen ? (
    <ConfirmationDialog
      title="Delete Jockey?"
      description={
        <>
          Are you sure you want to delete this Jockey? All information will be
          lost.
        </>
      }
      onConfirmation={(): void => deleteMutation.mutate()}
      confirmationLabel={<>Yes, Delete</>}
      onCancel={close}
    />
  ) : null;
};
