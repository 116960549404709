import React, { FC, PropsWithChildren } from 'react';

export interface TabPanelProps {
  hidden?: boolean;
}

export const TabPanel: FC<TabPanelProps & PropsWithChildren> = ({
  hidden,
  children,
}) => {
  return (
    <div role="tabpanel" hidden={hidden}>
      {!hidden && children}
    </div>
  );
};
