import { useQuery } from 'react-query';
import { fetchTrailerStatusById } from '../api/trailers';
import { TrailerStatus } from '../types';

export interface UseTrailerStatusResult {
  trailerStatusData?: TrailerStatus;
  isTrailerStatusLoading?: boolean;
}

export const useTrailerStatus = (
  trailerId?: string
): UseTrailerStatusResult => {
  const { data: trailerStatusData, isLoading: isTrailerStatusLoading } =
    useQuery(
      ['trailer_status', trailerId],
      () => fetchTrailerStatusById(trailerId),
      {
        enabled: !!trailerId,
        retry: false,
        retryOnMount: true,
        refetchOnWindowFocus: false,
        refetchInterval: 1000 * 60 * 10,
        cacheTime: 0,
      }
    );

  return {
    trailerStatusData,
    isTrailerStatusLoading,
  };
};
