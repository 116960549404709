import React, { FC, ReactNode } from 'react';
import { List, ListItem, ListItemText, styled } from '@mui/material';

export interface DetailListCardViewProps {
  labelsToValues: [string, ReactNode][];
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  border: '1px solid #232C35',
  borderBottom: '0 none',
  paddingdX: theme.spacing(4),
  paddingY: theme.spacing(2),

  '&:first-of-type': {
    borderRadius: '8px 8px 0 0',
  },

  '&:last-child': {
    borderBottom: '1px solid #232C35',
    borderRadius: '0 0 8px 8px',
  },
}));

/**
 * Displays items in a list along with their labels.
 * @param labelsToValues Array of label to value pairs, each label must be unique.
 */
export const DetailListCardView: FC<DetailListCardViewProps> = ({
  labelsToValues,
}) => {
  return (
    <List disablePadding>
      {labelsToValues.map(([label, value]) => (
        <StyledListItem key={label}>
          <ListItemText primary={label} />
          <ListItemText primary={value} sx={{ textAlign: 'right' }} />
        </StyledListItem>
      ))}
    </List>
  );
};
