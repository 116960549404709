import { apiClient } from '../http-common';
import { AuthDetail, ResetPasswordForm } from '../../types';
import { AxiosResponse } from 'axios';

export const recoverPassword = (email: string): Promise<AuthDetail> => {
  return apiClient
    .post('/auth/password/reset/', { email })
    .then((res: AxiosResponse<AuthDetail>) => res.data);
};

export const resetPassword = (data: ResetPasswordForm): Promise<AuthDetail> =>
  apiClient
    .post('/auth/password/reset/confirm/', data)
    .then((res: AxiosResponse<AuthDetail>) => res.data);
