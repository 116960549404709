import { Administrator, Paginated } from '../../types';
import { apiClient } from '../http-common';
import { AxiosResponse } from 'axios';

export const fetchPaginatedAdministrators = (
  page = 1
): Promise<Paginated<Administrator>> =>
  apiClient
    .get('companyadmins/', { params: { page } })
    .then((res: AxiosResponse<Paginated<Administrator>>) => {
      return res.data;
    });

export const fetchAdministratorById = (
  adminId?: string
): Promise<Administrator> => {
  if (!adminId) {
    return Promise.reject('adminId is required');
  }

  return apiClient
    .get(`companyadmins/${adminId}/`)
    .then((res: AxiosResponse<Administrator>) => res.data);
};

export const addAdministrator = (
  admin: Administrator
): Promise<Administrator> => apiClient.post('companyadmins/', admin);

export const editAdministrator = (
  admin: Administrator
): Promise<Administrator> => apiClient.put(`companyadmins/${admin.id}/`, admin);

export const deleteAdministrator = (adminId?: string): Promise<void> =>
  apiClient.delete(`companyadmins/${adminId}/`);
