import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import AuthLayout from './layouts/AuthLayout/AuthLayout';
import DashboardLayout from './layouts/DashboardLayout/DashboardLayout';
import Signin from './screens/Signin/Signin';
import Missing from './screens/Missing/Missing';

import RequireAuth from './components/RequireAuth/RequireAuth';
import TrailerDetail from './screens/TrailerDetail/TrailerDetail';
import TrailerList from './screens/TrailerList/TrailerList';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AlertProvider } from './shared/contexts/AlertProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DriverDetailPage from './screens/DriverDetail/DriverDetail';
import DriverList from './screens/DriverList/DriverList';
import { DriverAssignment } from './screens/DriverAssignment/DriverAssignment';
import useAuth from './shared/hooks/useAuth';
import { RecoverPassword } from './screens/RecoverPassword/RecoverPassword';
import { RecoverPasswordEmailSent } from './screens/RecoverPasswordEmailSent/RecoverPasswordEmailSent';
import {
  MissingResetPasswordParams,
  ResetPassword,
} from './screens/ResetPassword/ResetPassword';
import { AssignmentList } from './screens/AssignmentList/AssignmentList';
import { AdministratorList } from './screens/AdministratorList/AdministratorList';
import { AdministratorDetail } from './screens/AdministratorDetail/AdministratorDetail';
import { CompanyList } from './screens/CompanyList/CompanyList';
import { RequireCompany } from './components/RequireCompany/RequireCompany';
import { RequireRole } from './components/RequireRole/RequireRole';
import { hasMatchingRole } from './shared/utils/roleUtils';
import { Reports } from './screens/Reports/Reports';
import { AssignmentReports } from './screens/AssignmentReports/AssignmentReports';
import { YardJockeyList } from './screens/YardJockeyList/YardJockeyList';

const StyledAppContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100vh',
}));

const queryClient = new QueryClient();

const UserRedirects: FC = () => {
  const { activeCompany } = useAuth();
  const userRoles = activeCompany?.role;

  if (hasMatchingRole(['SUPERUSER', 'OWNER', 'MANAGER'], userRoles)) {
    return <Navigate to="/trailers" replace />;
  } else if (hasMatchingRole('DISPATCHER', userRoles)) {
    return <Navigate to="/company-assignments" replace />;
  } else if (hasMatchingRole('DRIVER', userRoles)) {
    return <Navigate to="/assignments" replace />;
  } else {
    return (
      <div>
        Your user does not have an assigned role. Contact your administrator.
      </div>
    );
  }
};

function App(): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <StyledAppContainer>
        <AlertProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Routes>
              {/* public routes */}
              <Route path="auth" element={<AuthLayout />}>
                <Route path="signin" element={<Signin />} />
                <Route path="recover" element={<RecoverPassword />} />
                <Route
                  path="recovery_sent"
                  element={<RecoverPasswordEmailSent />}
                />
                <Route path="reset">
                  <Route path=":uid/:token" element={<ResetPassword />} />
                  <Route
                    index
                    path="*"
                    element={<MissingResetPasswordParams />}
                  />
                </Route>
              </Route>

              <Route element={<RequireAuth />}>
                <Route path="auth/company" element={<DashboardLayout />}>
                  <Route index element={<CompanyList />} />
                </Route>

                <Route element={<RequireCompany />}>
                  <Route path="/" element={<DashboardLayout />}>
                    <Route index element={<UserRedirects />} />
                  </Route>

                  <Route
                    element={<RequireRole userTypes={['SUPERUSER', 'OWNER']} />}
                  >
                    <Route path="administrators" element={<DashboardLayout />}>
                      <Route index element={<AdministratorList />} />
                      <Route
                        path=":adminId"
                        element={<AdministratorDetail />}
                      />
                    </Route>
                  </Route>

                  <Route
                    element={
                      <RequireRole
                        userTypes={[
                          'SUPERUSER',
                          'OWNER',
                          'MANAGER',
                          'DISPATCHER',
                        ]}
                      />
                    }
                  >
                    <Route path="trailers" element={<DashboardLayout />}>
                      <Route index element={<TrailerList />} />
                      <Route path=":trailerId" element={<TrailerDetail />} />
                    </Route>
                    <Route path="drivers" element={<DashboardLayout />}>
                      <Route index element={<DriverList />} />
                      <Route path=":driverId" element={<DriverDetailPage />} />
                    </Route>
                    <Route path="jockeys" element={<DashboardLayout />}>
                      <Route index element={<YardJockeyList />} />
                    </Route>
                  </Route>

                  <Route
                    element={
                      <RequireRole
                        userTypes={[
                          'SUPERUSER',
                          'OWNER',
                          'MANAGER',
                          'DISPATCHER',
                        ]}
                      />
                    }
                  >
                    <Route
                      path="company-assignments"
                      element={<DashboardLayout />}
                    >
                      <Route index element={<AssignmentList />} />
                    </Route>
                  </Route>

                  <Route
                    element={
                      <RequireRole userTypes={['DRIVER', 'SUPERUSER']} />
                    }
                  >
                    <Route path="assignments" element={<DashboardLayout />}>
                      <Route index element={<DriverAssignment />} />
                    </Route>
                  </Route>
                  <Route
                    element={
                      <RequireRole
                        userTypes={[
                          'DISPATCHER',
                          'MANAGER',
                          'OWNER',
                          'SUPERUSER',
                        ]}
                      />
                    }
                  >
                    <Route path="reports" element={<DashboardLayout />}>
                      <Route element={<Reports />}>
                        <Route index element={<Navigate to="assignments" />} />
                        <Route
                          path="assignments"
                          element={<AssignmentReports />}
                        ></Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
              <Route path="*" element={<Missing />} />
            </Routes>
          </LocalizationProvider>
        </AlertProvider>
      </StyledAppContainer>
    </QueryClientProvider>
  );
}

export default App;
