import React, { FC, ReactNode } from 'react';
import { Box, Button, Stack, SxProps, Theme, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

export interface DetailLayoutProps {
  title: string;
  subtitle?: string;
  action?: () => void;
  actionLabel?: string | ReactNode;
  hasBackButton?: boolean;
  children?: ReactNode;
  sx?: SxProps<Theme>;
}

const DetailLayout: FC<DetailLayoutProps> = ({
  title,
  subtitle,
  action,
  actionLabel,
  hasBackButton = true,
  children,
  sx,
}) => {
  const navigate = useNavigate();
  return (
    <Box sx={sx}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          mt: 1,
          mb: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {hasBackButton && (
            <Button
              variant="text"
              sx={{
                minWidth: 0,
                padding: 0.25,
                position: 'relative',
                top: '1px',
                mr: 1,
              }}
              onClick={() => navigate(-1)}
              aria-label="Go back"
            >
              <ArrowBackIcon fontSize="small" />
            </Button>
          )}
          <Stack>
            <Typography variant="h6">{title}</Typography>
            {subtitle && (
              <Typography variant="subtitle1">{subtitle}</Typography>
            )}
          </Stack>
        </Box>
        {action && (
          <Button variant="contained" onClick={action}>
            {actionLabel}
          </Button>
        )}
      </Box>
      {children}
    </Box>
  );
};

export default DetailLayout;
